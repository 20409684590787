import { AiOutlineUser } from 'react-icons/ai'
import { FaClipboardList } from 'react-icons/fa'
import { FaRecycle, FaTruckArrowRight, FaBuilding, FaChartSimple, FaGear } from 'react-icons/fa6'
import { createBrowserRouter } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import SimpleLayout from 'src/layouts/SimpleLayout'
import ErrorPage from 'src/pages/ErrorPage'
import DashboardPage from 'src/pages/DashboardPage'
import LoginPage from 'src/pages/LoginPage'
import NotFound from 'src/pages/NotFound'
import OnboardingPage from 'src/pages/OnboardingPage'
import OrganizationProfile from 'src/pages/OrganizationProfile'
import OrganizationsPage from 'src/pages/OrganizationsPage'
import PickupsPage from 'src/pages/PickupsPage'
import TraceabilityPage from 'src/pages/TraceabilityPage'
import UsersPage from 'src/pages/UsersPage'
import ProvidersPage from 'src/pages/Providers'
import EmailVerification from 'src/pages/EmailVerificationPage'
import VerifyEmailLayout from './layouts/VerifyEmailLayout'
import ConfigurationPage from './pages/ConfigurationPage'
import LoginProviderPage from './pages/LoginProviderPage'
import ProvidersPanelPage from './pages/ProvidersPanelPage'
import OnboardingProviderPage from './pages/OnboardingProviderPage'
import { Roles } from './constants'
import ProviderProfilePage from './pages/ProviderProfilePage'

export const Routes = {
  HOME: '/',
  LOGIN: '/login',
  LOGIN_PROVIDER: '/login/provider',
  USERS: '/users',
  PROFILE: '/profile',
  PROVIDER_PROFILE: '/profile-provider',
  ORGANIZATIONS: '/organizations',
  ONBOARDING: '/onboarding',
  ONBOARDING_PROVIDER: '/onboarding/provider',
  PROVIDERS_PANEL: '/providers-panel',
  PROVIDERS: '/providers',
  PICKUPS: '/pickups',
  TRACEABILITY: '/traceability',
  CONFIGURATION: '/configuration',
  EMAIL_VERIFICATION: '/email-verification'
}

const { ADMIN, ORGANIZATION_ADMIN, PROVIDER_ADMIN } = Roles

export const menuItems = [
  {
    label: 'Panel',
    route: Routes.HOME,
    IconComponent: FaChartSimple,
    roles: [ORGANIZATION_ADMIN, PROVIDER_ADMIN]
  },
  {
    label: 'Usuarios',
    route: Routes.USERS,
    IconComponent: AiOutlineUser,
    roles: [ADMIN]
  },
  {
    label: 'Organizaciones',
    route: Routes.ORGANIZATIONS,
    IconComponent: FaBuilding,
    roles: [ADMIN]
  },
  {
    label: 'Proveedores',
    route: Routes.PROVIDERS_PANEL,
    IconComponent: FaRecycle,
    roles: [ADMIN]
  },
  {
    label: 'Proovedores',
    route: Routes.PROVIDERS,
    IconComponent: FaRecycle,
    roles: [ORGANIZATION_ADMIN]
  },
  {
    label: 'Recolecciones',
    route: Routes.PICKUPS,
    IconComponent: FaTruckArrowRight,
    roles: [ORGANIZATION_ADMIN, PROVIDER_ADMIN]
  },
  {
    label: 'Trazabilidad',
    route: Routes.TRACEABILITY,
    IconComponent: FaClipboardList,
    roles: [ORGANIZATION_ADMIN, PROVIDER_ADMIN]
  }
]

export const dropdownItems = [
  {
    label: 'Perfil Empresa',
    route: Routes.PROFILE,
    IconComponent: FaBuilding,
    roles: [ORGANIZATION_ADMIN]
  },
  {
    label: 'Perfil Proovedor',
    route: Routes.PROVIDER_PROFILE,
    IconComponent: FaBuilding,
    roles: [PROVIDER_ADMIN]
  },
  {
    label: 'Configuración',
    route: Routes.CONFIGURATION,
    IconComponent: FaGear,
    roles: [ORGANIZATION_ADMIN, ADMIN, PROVIDER_ADMIN]
  }
]

export const items = [...menuItems, ...dropdownItems]

const router = createBrowserRouter([
  {
    path: '/login',
    element: <SimpleLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <LoginPage /> },
      { path: Routes.LOGIN_PROVIDER, element: <LoginProviderPage /> },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: '/',
    element: <DashboardLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <DashboardPage /> },
      { path: Routes.LOGIN, element: <LoginPage /> },
      { path: Routes.USERS, element: <UsersPage /> },
      { path: Routes.ORGANIZATIONS, element: <OrganizationsPage /> },
      { path: Routes.PROVIDERS_PANEL, element: <ProvidersPanelPage /> },
      { path: Routes.PROVIDERS, element: <ProvidersPage /> },
      { path: Routes.PICKUPS, element: <PickupsPage /> },
      { path: Routes.TRACEABILITY, element: <TraceabilityPage /> },
      { path: Routes.PROFILE, element: <OrganizationProfile /> },
      { path: Routes.PROVIDER_PROFILE, element: <ProviderProfilePage /> },
      { path: Routes.CONFIGURATION, element: <ConfigurationPage /> },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: '/onboarding',
    element: <SimpleLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <OnboardingPage /> },
      { path: Routes.ONBOARDING_PROVIDER, element: <OnboardingProviderPage /> }
    ]
  },
  {
    path: '/email-verification',
    element: <VerifyEmailLayout />,
    errorElement: <ErrorPage />,
    children: [{ index: true, element: <EmailVerification /> }]
  }
])

export default router
