export const Roles = {
  ADMIN: 'admin',
  USER: 'user',
  ORGANIZATION_ADMIN: 'organization_admin',
  PROVIDER_ADMIN: 'provider_admin'
}

export const organizationTypes = [
  {
    label: 'Privada',
    value: 'private'
  },
  {
    label: 'ONG',
    value: 'ngo'
  },
  {
    label: 'Cooperativa',
    value: 'cooperative'
  },
  {
    label: 'Fundación',
    value: 'foundation'
  }
]

export const fleetTypes = [
  {
    label: 'Combustión',
    value: 'combustion'
  },
  {
    label: 'Eléctrico',
    value: 'electric'
  },
  {
    label: 'Híbrido',
    value: 'hibrid'
  }
]

export const operationSectors = [
  { label: 'Gerencia', value: 'management' },
  { label: 'Administración', value: 'administration' },
  { label: 'Gestión ambiental', value: 'environmental_management' },
  { label: 'Producción', value: 'production' },
  { label: 'Ventas', value: 'sales' },
  { label: 'Contabilidad y finanzas', value: 'accounting_and_finance' },
  { label: 'Recursos humanos', value: 'human_resources' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Otros', value: 'others' }
]

export const finalDestination = [
  { label: 'Reciclaje', value: 'recycling' },
  { label: 'Reutilización', value: 'reuse' },
  { label: 'Disposición final', value: 'final_disposal' }
]
