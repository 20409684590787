import moment from 'moment'
import 'moment/dist/locale/es'
moment.locale('es')

export default function formatDate (date, format) {
  if (format === 'fromNow') return moment.utc(date).local().fromNow()

  return moment.utc(date).local().format(format ?? 'LLL')
}

export const disablePastDates = (current) => {
  return moment().add(-1, 'days') >= current ||
       moment().add(1, 'month') <= current
}

export const expiredDate = (date) => {
  return new Date(date) < new Date()
}
