import { getLocalTimeZone, today } from '@internationalized/date'
import { Button } from '@nextui-org/react'
import { useState } from 'react'
import CertificateCard from 'src/pages/Providers/components/providers/CertificateCard'
import CustomDatePicker from './CustomDatePicker'
import CustomFileUpload from './CustomFilePicker'

export default function FileField ({
  label,
  fieldName,
  formikField,
  setFieldValue,
  expiration = true
}) {
  const [renew, setRenew] = useState(false)

  return (
    <div className="flex flex-col gap-2">
      {!renew && formikField?.file_path && (
        <div className="flex items-center gap-1">
          <CertificateCard c={formikField} />
          <Button size="sm" color="secondary" onClick={() => setRenew(true)}>
            Renovar
          </Button>
        </div>
      )}

      {(renew || formikField?.file_path == null) && (
        <div className="flex items-center gap-4 mb-2">
          {label && <span className="min-w-[80px]">{label}</span>}
          <CustomFileUpload
            onChange={(file) => {
              setFieldValue(fieldName + '.file', file)
            }}
          />
          {expiration && <CustomDatePicker
            label="Vigencia"
            className="w-[250px]"
            value={formikField?.expiration_date}
            minValue={today(getLocalTimeZone())}
            onChange={(value) => {
              setFieldValue(fieldName + '.expiration_date', value)
            }}
          />}
        </div>
      )}
    </div>
  )
}
