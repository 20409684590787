import { getLocalTimeZone, today } from '@internationalized/date'
import { Input, Select, SelectItem } from '@nextui-org/react'
import CustomDatePicker from 'src/components/CustomDatePicker'
import departments from 'src/data/departments.json'

export default function VehicleQualificationForm ({ formik }) {
  const { values, setFieldValue, getFieldProps } = formik

  return (
    <div className="flex flex-col gap-2">
      <Select
        size="sm"
        label="Departamento"
        selectedKeys={values.vehicle_qualification?.department}
        onSelectionChange={(value) =>
          setFieldValue('vehicle_qualification.department', value)
        }
      >
        {departments?.map((l) => (
          <SelectItem key={l.value} value={l.value}>
            {l.label}
          </SelectItem>
        ))}
      </Select>
      <Input
        {...getFieldProps('vehicle_qualification.enablement_number')}
        size="sm"
        label="Número de Habiltación"
      />
      <CustomDatePicker
        label="Vigencia"
        minValue={today(getLocalTimeZone())}
        value={values.vehicle_qualification?.expiration_date}
        onChange={(value) =>
          setFieldValue('vehicle_qualification.expiration_date', value)
        }
      />
    </div>
  )
}
