import { Button } from '@nextui-org/react'
import { PiWarningCircleBold } from 'react-icons/pi'
import CustomModal from 'src/components/CustomModal'
import useDeleteTraceability from '../hooks/useDeleteTraceability'

export default function TraceabilityDeleteModal({ isOpen, onOpenChange, traceability }) {
  if (!traceability) return null

  const { mutate } = useDeleteTraceability(onOpenChange)
  const handleDelete = (id) => {
    mutate({ id })
  }

  return (
    <CustomModal size="lg" isOpen={isOpen} onOpenChange={onOpenChange}>
      <div className="flex flex-col gap-4 p-2">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-primary">Eliminar Trazabilidad</h2>
          <p className="text-sm max-w-sm text-slate-500">
            ¿Está seguro que desea eliminar esta trazabilidad?
          </p>
        </div>

        <div className="flex gap-2 p-4 bg-amber-100 rounded-md">
          <PiWarningCircleBold className="text-amber-500 text-xl w-10" />
          <div className="flex flex-col gap-1 ">
            <span className="text-md text-amber-500">Advertencia</span>
            <p className="text-xs max-w-md font-extralight text-amber-500">
              Al eliminar una trazabilidad, esta acción no se puede deshacer.
            </p>
          </div>
        </div>
        <Button color="danger" onPress={() => handleDelete(traceability.id)}>
          Eliminar
        </Button>
      </div>
    </CustomModal>
  )
}
