import { useLocation, useNavigate } from 'react-router-dom'
import LoginCard from './components/LoginCard'
import { FiChevronsRight } from 'react-icons/fi'
import { Button } from '@nextui-org/react'
import { Routes } from 'src/routes'

export default function LoginPage() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const email = state?.email || ''

  return (
    <div className="grid grid-cols-1 items-center p-4">
      <div className="flex flex-col items-center gap-6">
        <LoginCard email={email} />
        <Button
          color="primary"
          size="lg"
          variant="light"
          className="md:absolute md:top-8 md:right-8"
          endContent={<FiChevronsRight />}
          onPress={() => navigate(Routes.LOGIN_PROVIDER)}
        >
          Ingresar como proovedor
        </Button>
        <span className="text-gray-600 text-sm">
          <strong className="text-black">2024 Teko Labs SAS.</strong> Todos los derechos reservados.
        </span>
      </div>
      {/* <div className='hidden sm:block'>
        <LoginCarousel />
      </div> */}
    </div>
  )
}
