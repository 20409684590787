import { Tabs, Tab } from '@nextui-org/react'
import TabOrganizationEdit from './components/TabOrganizationEdit'
import TabBranchOffices from './components/TabBranchOffices'

export default function OrganizationProfile () {
  return (
    <div className="flex w-full flex-col px-8">
      <Tabs variant='underlined' color='primary'>
        <Tab key="profile" title="Perfil">
          <TabOrganizationEdit />
        </Tab>
        <Tab key="branch_offices" title="Sucursales">
          <TabBranchOffices />
        </Tab>
      </Tabs>
    </div>
  )
}
