import { I18nProvider } from '@react-aria/i18n'
import { DatePicker } from '@nextui-org/react'

export default function CustomDatePicker (props) {
  const { label, value, onChange, ...fieldProps } = props

  return (
    <I18nProvider>
      <DatePicker
        size="sm"
        label={label}
        value={value}
        onChange={onChange}
        {...fieldProps}
      />
    </I18nProvider>
  )
}
