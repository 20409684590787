import { useState } from 'react'

export default function useMinimizeNavbar () {
  const [minimized, setMinimized] = useState(false)

  const toggleMinimized = () => {
    setMinimized(prev => !prev)
  }

  return { minimized, toggleMinimized }
}
