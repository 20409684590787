export default function useCheckValidCertificates (provider) {
  let isExpired = false

  for (const c of provider.certifications) {
    if (c.expiration_date && new Date(c.expiration_date) < new Date()) {
      isExpired = true
    }
  }
  for (const c of provider.qualifications) {
    if (c.expiration_date && new Date(c.expiration_date) < new Date()) {
      isExpired = true
    }
  }

  return isExpired
}
