import { GoDotFill } from 'react-icons/go'
import { FaFile } from 'react-icons/fa6'
import { Button, Tooltip } from '@nextui-org/react'
import formatDate, { expiredDate } from 'src/utils/date'
import translate from 'src/utils/translate'
import S3Manager from 'src/services/S3Manager'

const s3Manager = new S3Manager()

export default function CertificateCard ({ c }) {
  if (!c) return null

  async function downloadFile (resource) {
    const url = await s3Manager.downloadFile(resource)
    window.open(url)
  }

  return (
    <div
      key={c.id}
      className="w-full bg-gray-50 rounded-md flex items-center justify-between p-1 px-2 m-1 my-2"
    >
      <p>{translate(c.type)}</p>
      <div className="flex gap-2">
        <Tooltip content="Descargar Certificado">
          <Button
            isIconOnly
            color="primary"
            variant="solid"
            size="sm"
            onPress={() => downloadFile(c.file_path)}
          >
            <FaFile size={18} />
          </Button>
        </Tooltip>
        {c.expiration_date && (
          <Tooltip
            content={`Expira en ${formatDate(c.expiration_date, 'fromNow')}`}
          >
            <Button
              isIconOnly
              color={expiredDate(c.expiration_date) ? 'danger' : 'success'}
              variant="flat"
              size="sm"
            >
              <GoDotFill size={18} />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
