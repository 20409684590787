import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/traceability')

export default function useDeleteTraceability (onOpenChange) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => {
      return client.delete(id)
    },
    onError: (error) => {
      toast.error(`Error al eliminar la trazabilidad, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['traceability'])
      onOpenChange()
      toast.success('Trazabilidad eliminada correctamente')
    }
  })
}
