import { Link } from '@nextui-org/react'

export default function NotFound () {
  return (
    <div className="flex flex-row p-6 gap-10 h-96 items-center justify-center">
        <div className='flex flex-col gap-3'>
            <h1 className="text-6xl text-primary font-extrabold">404 Page not found</h1>
            <h4 className="text-2xl font-bold max-w-sm">Oops... It looks that you missed the route</h4>
            <Link href='/' color='primary' className='mt-2 w-fit'>Return home</Link>
        </div>
    </div>
  )
}
