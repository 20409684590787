import { Button, useDisclosure } from '@nextui-org/react'
import Navigation from './Navigation'
import AddTraceabilityItem from './AddTraceabilityItem'
import useGetPickup from 'src/pages/PickupsPage/hooks/useGetPickup'
import TraceabilityItemCard from './TraceabilityItemCard'
import { IoAdd } from 'react-icons/io5'

export default function StepTwo({ formik, onPreviousStep, traceability }) {
  const { values, handleSubmit } = formik
  const { isOpen, onOpenChange, onOpen } = useDisclosure()
  const { data: pickup } = useGetPickup({ id: values.pickup_id?.values().next().value })

  const handleDelete = (id) => {
    formik.setFieldValue(
      'items',
      values.items.filter((item) => item.waste_id !== id)
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 min-h-[400px]">
        <Button
          color="primary"
          onPress={onOpen}
          variant="light"
          className="self-end"
          startContent={<IoAdd size={20} />}
        >
          Trazar Resiudo
        </Button>
        {values.items.length === 0 && (
          <div className="flex min-h-[200px] items-center justify-center">
            <p className="text-gray-400">No se han asociado residuos a esta trazabilidad</p>
          </div>
        )}
        {values.items?.map((item, index) => (
          <TraceabilityItemCard key={index} item={item} pickup={pickup} onDelete={handleDelete} />
        ))}
      </div>
      <Navigation step={2} onPreviousStep={onPreviousStep}>
        <Button
          type="submit"
          variant="bordered"
          color="primary"
          className="px-10 mt-2"
          isDisabled={!formik.isValid || values.items.length === 0}
          onPress={handleSubmit}
        >
          {traceability ? 'Editar Trazabilidad' : 'Crear Trazabilidad'}
        </Button>
      </Navigation>
      <AddTraceabilityItem
        formik={formik}
        selectedPickup={pickup}
        items={values?.items}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      />
    </div>
  )
}
