import { Button, Checkbox, useDisclosure } from '@nextui-org/react'
import { useState } from 'react'
import useAssignProvider from '../../hooks/useAssignProvider'
import useGetOrganizationProviders from '../../hooks/useGetOrganizationProviders'
import useGetProviders from '../../hooks/useGetProviders'
import ProviderCardSmall from '../providers/ProviderCardSmall'
import WastesStep from './WastesStep'
import CustomModal from 'src/components/CustomModal'

export default function ProviderPreviewModal({ onOpenChange, onOpenCreate }) {
  const [selectedProvider, setSelectedProvider] = useState(null)
  const {
    isOpen: isOpenWaste,
    onOpen: onOpenWaste,
    onOpenChange: onOpenChangeWaste
  } = useDisclosure()
  const { data: unassignedProviders, isPending } = useGetProviders({ unassigned: true })
  const { data: providers } = useGetOrganizationProviders()
  const { mutate } = useAssignProvider(onOpenChange)

  const handleNotFound = () => {
    onOpenChange()
    onOpenCreate()
  }

  const handleSave = () => {
    onOpenChangeWaste()
    mutate({ data: { providers: [selectedProvider.id] } })
  }

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-primary text-2xl">Gestores Existentes</h1>
        <p className="text-gray-400 max-w-sm">
          Primero verifica que tu proovedor no se encuentre ya creado en el sistema.
        </p>
      </div>
      <div className="flex flex-col gap-4 py-4">
        {unassignedProviders?.map((provider) => (
          <div className="flex gap-2" key={provider.id}>
            <Checkbox
              isSelected={selectedProvider === provider}
              onValueChange={() => setSelectedProvider(provider)}
            />
            <ProviderCardSmall key={provider.id} provider={provider} />
          </div>
        ))}
      </div>
      <div>
        <span className="text-gray-400">Proovedores ya cargados en tu empresa:</span>
        <div className="flex flex-col gap-4 py-4">
          {providers?.map((provider) => (
            <div className="flex gap-2" key={provider.id}>
              <ProviderCardSmall key={provider.id} provider={provider} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-2">
        <Button color="primary" variant="bordered" fullWidth onPress={handleNotFound}>
          No he encontrado a mi proovedor
        </Button>
        <Button
          isDisabled={!selectedProvider}
          color="primary"
          variant="solid"
          fullWidth
          onPress={onOpenWaste}
        >
          Siguiente
        </Button>
      </div>
      <CustomModal isOpen={isOpenWaste} onOpenChange={onOpenChangeWaste}>
        <h1 className="text-xl text-primary">Verifique los residuos del proovedor</h1>
        <WastesStep
          provider={selectedProvider}
          actionable={false}
          onConfirm={() => alert('confirmed')}
        />
        <Button
          isDisabled={!selectedProvider}
          isLoading={isPending}
          color="primary"
          variant="solid"
          fullWidth
          onPress={handleSave}
        >
          Guardar
        </Button>
      </CustomModal>
    </>
  )
}
