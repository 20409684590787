import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Chip,
  Button,
  Tooltip,
  useDisclosure
} from '@nextui-org/react'
import { IoCreateOutline, IoTrashOutline } from 'react-icons/io5'
import PickupDeleteModal from './PickupDeleteModal'
import { useState } from 'react'
import CustomModal from 'src/components/CustomModal'
import EditPickupForm from './EditPickupForm'
import moment from 'moment'
import styled from 'styled-components'
import { Roles } from 'src/constants'

const columns = [
  {
    key: 'detail',
    label: 'DETALLE'
  },
  {
    key: 'created_at',
    label: 'FECHA'
  },
  {
    key: 'branch_office',
    label: 'SUCURSAL'
  },
  {
    key: 'provider',
    label: 'PROVEEDOR'
  },
  {
    key: 'certificate_number',
    label: 'CONSTANCIA'
  },
  {
    key: 'vehicle_registration_number',
    label: 'MATRÍCULA'
  },
  {
    key: 'waste_type',
    label: 'RESIDUOS'
  },
  {
    key: 'actions',
    label: 'ACCIONES'
  }
]

export default function PickupsTable({ user, data, isFetching, branchOffices }) {
  if (!data) return null

  const [selectedPickup, setSelectedPickup] = useState(null)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onOpenChange: onOpenChangeEdit } = useDisclosure()

  const handleDelete = (pickup) => {
    setSelectedPickup(pickup)
    onOpen()
  }

  const handleEdit = (pickup) => {
    setSelectedPickup(pickup)
    onOpenEdit()
  }

  const renderBranchOffice = (branchOfficeId) => {
    const bo = branchOffices?.find((b) => b.id === branchOfficeId)
    return `${bo?.address}, ${bo?.location}, ${bo?.department}`
  }

  const TableWrapper = styled(Table)`
    > div {
      min-height: 550px;
    }
  `

  return (
    <>
      <TableWrapper>
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={data && data.items}
          isLoading={isFetching}
          loadingContent={<Spinner />}
          emptyContent={
            <div className="flex items-center justify-center min-h-[480px]">
              <p className="text-xl">No Hay Recolecciones Registradas.</p>
            </div>
          }
        >
          {(pickup) => (
            <TableRow key={pickup.id}>
              <TableCell>{pickup.detail}</TableCell>
              <TableCell>{moment(pickup.pickup_date, 'yyyy-MM-DD').format('DD/MM/YY')}</TableCell>
              <TableCell>
                <p className="max-w-xs line-clamp-1">
                  {renderBranchOffice(pickup?.branch_office_id)}
                </p>
              </TableCell>
              <TableCell>{pickup.provider.name}</TableCell>
              <TableCell>{pickup.certificate_number}</TableCell>
              <TableCell>{pickup.vehicle_registration_number}</TableCell>
              <TableCell>
                {pickup.wastes.map((w) => (
                  <Tooltip key={w.id} content={w.category_name}>
                    <Chip color="primary" className="mx-1">
                      {w.type}
                    </Chip>
                  </Tooltip>
                ))}
              </TableCell>

              <TableCell>
                {user.role === Roles.ORGANIZATION_ADMIN && (
                  <>
                    <Tooltip content="Editar">
                      <Button isIconOnly variant="light" onPress={() => handleEdit(pickup)}>
                        <IoCreateOutline />
                      </Button>
                    </Tooltip>
                    <Tooltip content="Eliminar">
                      <Button
                        isIconOnly
                        variant="light"
                        color="danger"
                        onPress={() => handleDelete(pickup)}
                      >
                        <IoTrashOutline />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableWrapper>
      <PickupDeleteModal isOpen={isOpen} onOpenChange={onOpenChange} pickup={selectedPickup} />
      <CustomModal isOpen={isOpenEdit} onOpenChange={onOpenChangeEdit}>
        <h1 className="text-primary text-2xl mt-4">Editar Levante</h1>
        <EditPickupForm
          pickup={selectedPickup}
          onOpenChange={onOpenChangeEdit}
          branchOffices={branchOffices}
        />
      </CustomModal>
    </>
  )
}
