import { Button, Image, Tooltip } from '@nextui-org/react'
import { IoIosCreate, IoMdOpen } from 'react-icons/io'
import { MdCancel } from 'react-icons/md'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'
import useCheckValidCertificates from 'src/hooks/useCheckValidCertificates'
import { GoDotFill } from 'react-icons/go'
import { useSelector } from 'react-redux'

const CardTitle = ({ title, subtitle, extraStyles }) => {
  return (
    <div className={`col-span-1 flex flex-col ${extraStyles}`}>
      <span className="text-xs text-gray-400 tracking-wide uppercase">{title}</span>
      <div>
        <span className="text-md font-bold max-w-sm mb-1">{subtitle}</span>
      </div>
    </div>
  )
}

export default function ProviderCard({ provider, onOpen, onDelete, onEdit }) {
  const user = useSelector((state) => state.auth.user)
  const { fileUrl } = useGetS3ObjectUrl(provider?.logo)
  const areExpiredCertificates = useCheckValidCertificates(provider)

  return (
    <div className="grid grid-cols-12 items-center bg-white border-2 border-gray-100 p-6 rounded-xl ticketCard">
      <div className="flex gap-4 items-center col-span-3">
        <Image
          src={fileUrl}
          className="h-20 w-20 rounded-xl border-1 border-gray-100 object-contain"
        />
        <CardTitle title="NOMBRE" subtitle={provider?.name} />
      </div>
      <CardTitle
        title="CONTACTO"
        subtitle={provider?.responsible_name}
        extraStyles={'col-span-2'}
      />
      <CardTitle title="CORREO" subtitle={provider?.email} extraStyles={'col-span-2'} />
      <CardTitle title="RESIDUOS" subtitle={provider?.wastes?.length} />
      <div className={'flex flex-col'}>
        <span className="text-xs text-gray-400 tracking-wide uppercase">Certificados</span>
        <div className="flex gap-1 items-center">
          <GoDotFill
            size={20}
            className={`text-xl ${areExpiredCertificates ? 'text-red-500' : 'text-green-500'}`}
          />
          <span className="text-md font-bold max-w-sm mb-1">
            {areExpiredCertificates ? 'Vencidos' : 'Al día'}
          </span>
        </div>
      </div>
      <div className="flex gap-2 items-center justify-end col-span-3">
        <Tooltip content="Ver Detalles">
          <Button isIconOnly onPress={() => onOpen(provider)} variant="flat" size="lg">
            <IoMdOpen size={20} />
          </Button>
        </Tooltip>
        {user.id === provider.created_by && (
          <Tooltip content="Editar">
            <Button isIconOnly onPress={() => onEdit(provider)} variant="flat" size="lg">
              <IoIosCreate size={20} />
            </Button>
          </Tooltip>
        )}
        <Tooltip content="Desvincular">
          <Button isIconOnly onPress={() => onDelete(provider)} variant="flat" size="lg">
            <MdCancel size={20} />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
