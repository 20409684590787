import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/users')

export default function useGetUsers () {
  return useQuery({
    queryKey: ['users'],
    queryFn: client.getAll
  })
}
