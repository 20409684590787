import { Checkbox, Input, Select, SelectItem } from '@nextui-org/react'
import { Form, FormikProvider } from 'formik'
import { useState } from 'react'
import FileField from 'src/components/FileField'
import { organizationTypes } from 'src/constants'
import departments from 'src/data/departments.json'
import useGetLocations from 'src/hooks/useGetLocations'
import SolidWasteQualificationForm from './SolidWasteQualificationForm'
import VehicleQualificationForm from './VehicleQualificationForm'
import ImagePreviewer from 'src/components/ImagePreview'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'

export default function ProviderCreateForm({ formik }) {
  const { values, errors, touched, getFieldProps, setFieldValue } = formik
  const { locations } = useGetLocations(values.department?.values().next().value)
  const [solidWasteQualification, setSolidWasteQualification] = useState(
    !!values.solid_waste_qualification?.enablement_number
  )
  const [vehicleQualification, setVehicleQualification] = useState(
    !!values.vehicle_qualification?.enablement_number
  )

  const { fileUrl } = useGetS3ObjectUrl(formik.values?.logo)

  return (
    <div className="flex flex-col w-full gap-6 p-6 justify-center items-center ">
      <div className="flex items-center justify-center mb-3">
        <ImagePreviewer initialValue={fileUrl} onChange={(file) => setFieldValue('logo', file)} />
      </div>
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-4 w-full">
          <span className="text-xs font-bold tracking-wide uppercase text-primary">
            Información General
          </span>
          <div className="grid grid-cols-2 gap-2">
            <Input
              {...getFieldProps('name')}
              size="sm"
              isInvalid={Boolean(touched.name && errors.name)}
              errorMessage={touched.name && errors.name}
              label="Nombre de la empresa"
            />
            <Input
              {...getFieldProps('rut_number')}
              size="sm"
              isInvalid={Boolean(touched.rut_number && errors.rut_number)}
              errorMessage={touched.rut_number && errors.rut_number}
              label="Número de RUT"
            />
            <Select
              size="sm"
              label="Tipo de Empresa"
              selectedKeys={values.type}
              onSelectionChange={(value) => setFieldValue('type', value)}
              isInvalid={Boolean(touched.type && errors.type)}
              errorMessage={touched.type && errors.type}
            >
              {organizationTypes.map((l) => (
                <SelectItem key={l.value} value={l.value}>
                  {l.label}
                </SelectItem>
              ))}
            </Select>
            <Input
              {...getFieldProps('phone_number')}
              size="sm"
              isInvalid={Boolean(touched.phone_number && errors.phone_number)}
              errorMessage={touched.phone_number && errors.phone_number}
              label="Teléfono"
            />
            <Input
              {...getFieldProps('email')}
              size="sm"
              isInvalid={Boolean(touched.email && errors.email)}
              errorMessage={touched.email && errors.email}
              label="Email"
            />
            <Input
              {...getFieldProps('responsible_name')}
              size="sm"
              isInvalid={Boolean(touched.responsible_name && errors.responsible_name)}
              errorMessage={touched.responsible_name && errors.responsible_name}
              label="Nombre del responsable"
            />
            <Select
              size="sm"
              label="Departamento"
              selectedKeys={values.department}
              onSelectionChange={(value) => setFieldValue('department', value)}
            >
              {departments?.map((l) => (
                <SelectItem key={l.value} value={l.value}>
                  {l.label}
                </SelectItem>
              ))}
            </Select>
            <Select
              size="sm"
              label="Localidad"
              selectedKeys={values.location}
              onSelectionChange={(value) => setFieldValue('location', value)}
            >
              {locations?.map((l) => (
                <SelectItem key={l.name} value={l.name}>
                  {`${l.name} (${l.code})`}
                </SelectItem>
              ))}
            </Select>
            <Input
              {...getFieldProps('address')}
              size="sm"
              isInvalid={Boolean(touched.address && errors.address)}
              errorMessage={touched.address && errors.address}
              label="Dirección"
            />
          </div>

          <span className="text-xs font-bold tracking-wide uppercase text-primary">
            Certificados
          </span>
          <div className="flex flex-col">
            <FileField
              label="DGI"
              fieldName="dgi_certificate"
              formikField={formik.values.dgi_certificate}
              setFieldValue={setFieldValue}
            />
            <FileField
              label="BPS"
              fieldName="bps_certificate"
              formikField={formik.values.bps_certificate}
              setFieldValue={setFieldValue}
            />
            <FileField
              label="Seguro"
              fieldName="insurance_certificate"
              formikField={formik.values.insurance_certificate}
              setFieldValue={setFieldValue}
            />
          </div>

          <span className="text-xs font-bold tracking-wide uppercase text-primary">
            Habilitaciones
          </span>
          <Checkbox
            isSelected={vehicleQualification}
            onValueChange={setVehicleQualification}
            size="sm"
          >
            Habilitación vehicular departamental
          </Checkbox>
          {vehicleQualification && <VehicleQualificationForm formik={formik} />}

          <Checkbox
            isSelected={solidWasteQualification}
            onValueChange={setSolidWasteQualification}
            size="sm"
          >
            Habilitación de residuos sólidos industriales
          </Checkbox>
          {solidWasteQualification && <SolidWasteQualificationForm formik={formik} />}
        </Form>
      </FormikProvider>
    </div>
  )
}
