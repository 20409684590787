import { useState } from 'react'
import {
  Navbar,
  Link,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  NavbarContent
} from '@nextui-org/react'
import ProfileDropdown from './ProfileDropdown'
import { useSelector } from 'react-redux'
import { menuItems } from 'src/routes'
import useGetProvider from 'src/hooks/useGetProvider'
import useGetOrganization from 'src/hooks/useGetOrganization'

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const { data } = user?.provider_id
    ? useGetProvider(user?.provider_id)
    : useGetOrganization(user?.organization_id)

  const filteredMenuItems = menuItems.filter((item) => item.roles.includes(user?.role))

  return (
    <Navbar
      maxWidth="full"
      position="static"
      onMenuOpenChange={setIsMenuOpen}
      className="bg-gray-100 py-1"
    >
      <NavbarContent className="lg:hidden" justify="start">
        <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} />
      </NavbarContent>

      <NavbarMenu className="bg-slate-50">
        {filteredMenuItems.map((item) => (
          <NavbarMenuItem key={item.label}>
            <Link className="w-full" href={item.route} size="lg">
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>

      <NavbarContent justify="end">
        <div className="flex gap-5 p-2 rounded-lg">
          <div className="flex flex-col items-end justify-center">
            <span className="font-semibold">{data?.name}</span>
            <span className="text-xs">{user?.email}</span>
          </div>
          <ProfileDropdown className="hidden lg:block" logo={data?.logo} user={user} />
        </div>
      </NavbarContent>
    </Navbar>
  )
}
