import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'
import Navbar from 'src/components/NavBar'
import Header from 'src/components/Header'
import useAuthentication from 'src/hooks/useAuthentication'
import useMinimizeNavbar from 'src/hooks/useMinimizeNavbar'
import { isMobile } from 'react-device-detect'
import Logo from 'src/assets/logo-with-text.svg'

export default function DashboardLayout() {
  useAuthentication()
  const { minimized, toggleMinimized } = useMinimizeNavbar()

  if (isMobile) {
    return (
      <div className="bg-sky-50 flex flex-col items-center justify-center min-h-screen p-4">
        <h1 className="max-w-md text-3xl text-primary text-center">
          Reko todavía no está disponible para dispositivos móviles ni tablets. Por favor, accede
          desde un computador.
        </h1>
        <img src={Logo} alt="Reko" className="w-36 my-10" />
      </div>
    )
  }

  return (
    <div className="bg-white min-h-screen">
      <div className="lg:grid lg:grid-cols-12">
        <div className={`hidden lg:block ${minimized ? 'lg:col-span-1' : 'lg:col-span-2'}`}>
          <Navbar minimized={minimized} toggleMinimized={toggleMinimized} />
        </div>
        <div className={`${minimized ? 'lg:col-span-11' : 'lg:col-span-10'}`}>
          <Header />
          <div className="bg-gray-100">
            <div className="bg-white sm:rounded-tl-3xl min-h-screen p-2">
              <Outlet />
            </div>
          </div>
          <Toaster richColors />
        </div>
      </div>
    </div>
  )
}
