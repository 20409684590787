import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/pickups')

export default function useEditPickup (onOpenChange) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => {
      return client.put(id, data)
    },
    onError: (error) => {
      toast.error(`Error al actualizar la recolección, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['pickups'])
      toast.success('Recolección actualizada exitosamente.')
      onOpenChange()
    }
  })
}
