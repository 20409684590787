import UsersTable from './UsersTable'
import useGetUsers from './hooks/useGetUsers'

export default function UsersPage () {
  const { data: users, isFetching } = useGetUsers()

  return (
    <div className='flex flex-col gap-4 p-4'>
      <UsersTable users={users} loading={isFetching}/>
    </div>
  )
}
