import { parseDate } from '@internationalized/date'
import { useFormik } from 'formik'
import { useState } from 'react'
import { toast } from 'sonner'
import { ProviderSchema } from 'src/schemas/ProviderSchema'
import S3Manager from 'src/services/S3Manager'
import formatDate from 'src/utils/date'
import useUpdateProvider from './useUpdateProvider'

export default function useUpdateProviderForm (
  provider,
  onOpenChange,
  resetStep
) {
  const [loading, setLoading] = useState(false)
  const s3Manager = new S3Manager()
  const { mutate } = useUpdateProvider(onOpenChange, resetStep)

  if (!provider) return null

  const initialValues = {
    logo: provider.logo,
    name: provider.name,
    rut_number: provider.rut_number,
    type: new Set([provider.type]),
    phone_number: provider.phone_number,
    email: provider.email,
    responsible_name: provider.responsible_name,
    department: new Set([provider.department]),
    location: new Set([provider.location]),
    fleet: new Set([provider.fleet]),
    address: provider.address,
    woman_in_leadership_positions: provider.woman_in_leadership_positions,
    gender_equity_policy: provider.gender_equity_policy,
    inclusion_programs: provider.inclusion_programs,
    footprint_reduction_programs: provider.footprint_reduction_programs
  }

  for (const c of provider.certifications) {
    initialValues[`${c.type}_certificate`] = {
      type: c.type,
      expiration_date: c.expiration_date ? parseDate(c?.expiration_date) : null,
      file: null,
      file_path: c.file_path
    }
  }

  for (const c of provider.qualifications) {
    initialValues[`${c.type}_qualification`] = {
      type: c.type,
      department: new Set([c.department]),
      expiration_date: c.expiration_date ? parseDate(c?.expiration_date) : null,
      enablement_number: c.enablement_number,
      file: null,
      file_path: c.file_path
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ProviderSchema,
    initialValues,
    onSubmit: async (values) => {
      setLoading(true)
      const id = provider.id

      try {
        if (values?.dgi_certificate?.file) {
          console.log('DGI', values.dgi_certificate.file)
          await s3Manager.uploadFile(
            values.dgi_certificate.file,
            `certifications/${id}/dgi_certificate.${values.dgi_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values?.insurance_certificate?.file) {
          console.log('INSURANCE', values.insurance_certificate.file)
          await s3Manager.uploadFile(
            values.insurance_certificate.file,
          `certifications/${id}/insurance_certificate.${values.insurance_certificate.file.name
            .split('.')
            .pop()}`
          )
        }

        if (values?.bps_certificate?.file) {
          console.log('BPS', values.bps_certificate.file)
          await s3Manager.uploadFile(
            values.bps_certificate.file,
          `certifications/${id}/bps_certificate.${values.bps_certificate.file.name
            .split('.')
            .pop()}`
          )
        }

        if (values?.iso_certificate?.file) {
          console.log('ISO', values.iso_certificate.file)
          await s3Manager.uploadFile(
            values.iso_certificate.file,
            `certifications/${id}/iso_certificate.${values.iso_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values?.b_company_certificate?.file) {
          console.log('B_COMPANY', values.b_company_certificate.file)
          await s3Manager.uploadFile(
            values.b_company_certificate.file,
            `certifications/${id}/b_company_certificate.${values.b_company_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values?.great_place_to_work_certificate?.file) {
          console.log('GREAT_PLACE_TO_WORK', values.great_place_to_work_certificate.file)
          await s3Manager.uploadFile(
            values.great_place_to_work_certificate.file,
            `certifications/${id}/great_place_to_work_certificate.${values.great_place_to_work_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values?.sustentability_commitment_certificate?.file) {
          console.log('SUSTENTABILITY_COMMITMENT', values.sustentability_commitment_certificate.file)
          await s3Manager.uploadFile(
            values.sustentability_commitment_certificate.file,
            `certifications/${id}/sustentability_commitment_certificate.${values.sustentability_commitment_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
      } catch (error) {
        toast.error('Ha ocurrido un error al subir los certificados')
        return
      }

      const data = {
        id,
        name: values.name,
        rut_number: values.rut_number,
        type: values.type.values().next().value,
        phone_number: values.phone_number,
        email: values.email,
        responsible_name: values.responsible_name,
        address: values.address,
        department: values.department.values().next().value,
        location: values.location.values().next().value,
        fleet: values.fleet.values().next().value
      }

      if (values?.logo instanceof File) {
        try {
          const path = `logos/${id}/logo.${values.logo.name.split('.').pop()}`
          await s3Manager.uploadFile(values.logo, path)
          data.logo = path
        } catch (error) {
          toast.error('Ha ocurrido un error al subiendo la imagen')
          return
        }
      }

      // Optional fields
      if (values?.dgi_certificate?.file) {
        data.dgi_certificate = {
          type: 'dgi',
          expiration_date: formatDate(
            values.dgi_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/dgi_certificate.${values.dgi_certificate.file.name
            .split('.')
            .pop()}`
        }
      }

      if (values?.bps_certificate?.file) {
        data.bps_certificate = {
          type: 'bps',
          expiration_date: formatDate(
            values.bps_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/bps_certificate.${values.bps_certificate.file.name
            .split('.')
            .pop()}`
        }
      }

      if (values?.insurance_certificate?.file) {
        data.insurance_certificate = {
          type: 'insurance',
          expiration_date: formatDate(
            values.insurance_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/insurance_certificate.${values.insurance_certificate.file.name
            .split('.')
            .pop()}`
        }
      }

      if (values?.vehicle_qualification?.expiration_date) {
        data.vehicle_qualification = {
          type: 'vehicle',
          department: values.vehicle_qualification.department.values().next().value,
          enablement_number: values.vehicle_qualification.enablement_number,
          expiration_date: formatDate(
            values.vehicle_qualification?.expiration_date.toDate(),
            'yyyy-MM-DD'
          )
        }
      }
      if (values?.solid_waste_qualification?.expiration_date) {
        data.solid_waste_qualification = {
          type: 'solid_waste',
          department: values.solid_waste_qualification.department.values().next().value,
          enablement_number: values.solid_waste_qualification.enablement_number,
          expiration_date: formatDate(
            values.solid_waste_qualification?.expiration_date.toDate(),
            'yyyy-MM-DD'
          )
        }
      }
      if (values?.iso_certificate?.file) {
        data.iso_certificate = {
          type: 'iso',
          file_path: `certifications/${id}/iso_certificate.${values.iso_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values?.b_company_certificate?.file) {
        data.b_company_certificate = {
          type: 'b_company',
          expiration_date: formatDate(
            values.b_company_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/b_company_certificate.${values.b_company_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values?.great_place_to_work_certificate?.file) {
        data.great_place_to_work_certificate = {
          type: 'great_place_to_work',
          expiration_date: formatDate(
            values.great_place_to_work_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/great_place_to_work_certificate.${values.great_place_to_work_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values?.woman_in_leadership_positions) {
        data.woman_in_leadership_positions =
          values.woman_in_leadership_positions
      }
      if (values?.gender_equity_policy) {
        data.gender_equity_policy = values.gender_equity_policy
      }
      if (values?.inclusion_programs) {
        data.inclusion_programs = values.inclusion_programs
      }
      if (values?.footprint_reduction_programs) {
        data.footprint_reduction_programs = values.footprint_reduction_programs
      }

      if (values?.sustentability_commitment_certificate?.file) {
        data.sustentability_commitment_certificate = {
          type: 'sustentability_commitment',
          file_path: `certifications/${id}/sustentability_commitment_certificate.${values.sustentability_commitment_certificate.file.name
            .split('.')
            .pop()}`
        }
      }

      mutate({ id: provider.id, data })
      setLoading(false)
    }
  })

  const { values } = formik

  const isFirstStepValid = () =>
    values.name &&
    values.rut_number &&
    values.phone_number &&
    values.email &&
    values.responsible_name &&
    values.address &&
    values.department?.size > 0 &&
    values.location?.size > 0 &&
    values.fleet?.size > 0 &&
    (values.bps_certificate?.file || values.bps_certificate?.file_path) &&
    values.bps_certificate?.expiration_date &&
    (values.dgi_certificate?.file || values.dgi_certificate?.file_path) &&
    values.dgi_certificate.expiration_date &&
    (values.insurance_certificate?.file || values.insurance_certificate?.file_path) &&
    values.insurance_certificate?.expiration_date

  const isSecondStepValid = () => true

  return { formik, loading, isFirstStepValid, isSecondStepValid }
}
