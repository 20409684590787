import { NextUIProvider } from '@nextui-org/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Analytics } from '@vercel/analytics/react'
import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import './index.css'
import router from './routes'
import { initSentry } from './services/sentry'
import { persistor, store } from './store/store'
import { antTheme } from './styles/antTheme'

const queryClient = new QueryClient()

initSentry()

ReactDOM.createRoot(document.getElementById('root')).render(
  <NextUIProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={antTheme} locale={esES}>
          <PersistGate loading={null} persistor={persistor}>
            <Analytics />
            <RouterProvider router={router} />
          </PersistGate>
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  </NextUIProvider>
)
