import { useEffect } from 'react'
import CreateOrganizationForm from './CreateOrganizationForm'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

export default function OnboardingOrganization({ onNextStep, formik }) {
  const user = useSelector((s) => s.auth.user)

  useEffect(() => {
    if (user.organization_id) {
      onNextStep()
      toast.success('Empresa creada previamente. Continuando al siguiente paso.')
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.1,
        ease: 'easeInOut'
      }}
    >
      <div className="flex flex-col gap-1 mb-5 items-center ">
        <h1 className="text-xl text-center text-primary">
          Comencemos creando el perfil de tu empresa
        </h1>
        {/* <p className="text-sm text-gray-400 text-center max-w-sm">
          Una empresa es un espacio de trabajo donde puedes invitar a tus compañeros y colaborar en
          su gestión.
        </p> */}
      </div>
      <CreateOrganizationForm onNextStep={onNextStep} formik={formik} user={user} />
    </motion.div>
  )
}
