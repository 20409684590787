import { Button, Image, Tooltip } from '@nextui-org/react'
import React from 'react'
import { IoNotifications } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Roles } from 'src/constants'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'
import formatDate from 'src/utils/date'

export default function TraceabilityCard({ item, onPressReminder, traceability = false }) {
  const user = useSelector((state) => state.auth.user)
  const { fileUrl } = useGetS3ObjectUrl(
    user?.role === Roles.ORGANIZATION_ADMIN ? item?.provider?.logo : item?.organization?.logo
  )

  return (
    <div className="flex items-center justify-between gap-3 p-2 px-3 border-1 border-gray-200 rounded-lg">
      <div className="flex gap-2 items-center">
        <Image
          src={fileUrl}
          className="h-10 w-10 rounded-xl border-1 border-gray-100 object-contain"
        />
        <span className="line-clamp-1">{item.detail}</span>
      </div>
      <div className="flex gap-2 items-center">
        <span className="text-xs text-gray-400">
          creada {formatDate(item.created_at, 'fromNow')}
        </span>
        {user?.role === Roles.ORGANIZATION_ADMIN && !traceability && (
          <Tooltip content="Notificar proovedor">
            <Button
              isIconOnly
              color="primary"
              size="sm"
              variant="flat"
              onPress={() => onPressReminder(item.id)}
            >
              <IoNotifications size={20} />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
