import { object, string } from 'yup'

export const OrganizationSchema = object().shape({
  logo: string(),
  name: string().required('Nombre requerido').min(4, 'El nombre de la empresa debe tener al menos 4 caracteres'),
  category: string().required('Categoría requerida'),
  subcategory: string().required('Categoría requerida'),
  department: string().required('Departamento requerido'),
  location: string().required('Localidad requerida'),
  address: string().required('Dirección requerida').min(10, 'La dirección de la empresa debe tener al menos 10 caracteres'),
  rut_number: string().required('RUT requerido'),
  operation_sector: string().required('Sector de operación requerido'),
  phone_number: string().required('Número de teléfono requerido').min(4, 'El número de teléfono de la empresa debe tener al menos 4 caracteres')
})
