import { Modal, ModalContent, ModalBody, Button } from '@nextui-org/react'
import { PiWarningCircleBold } from 'react-icons/pi'
import useActivateUserAccount from './hooks/useActivateUserAccount'

export default function ActivateUserModal({ isOpen, onOpenChange, selectedUser }) {
  const { mutate } = useActivateUserAccount(onOpenChange)
  const handleSubmit = () => {
    mutate({ id: selectedUser.id })
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="xl">
      <ModalContent>
        {(onClose) => (
          <ModalBody className="flex flex-col items-center  gap-5 p-10">
            <div className="text-center">
              <h2 className="text-2xl text-primary">Activar Cuenta</h2>
              <p className="text-sm max-w-xs text-slate-500">
                Active la cuenta del usuario para que cree su perfil y empiece a utilizar la
                plataforma.
              </p>
            </div>

            <div className="flex gap-2 p-4 bg-amber-100 rounded-md">
              <PiWarningCircleBold className="text-amber-500 text-xl w-10" />
              <div className="flex flex-col gap-1 ">
                <span className="text-md text-amber-500">Advertencia</span>
                <p className="text-xs max-w-md font-extralight text-amber-500">
                  Activando la cuenta usted le permitirá al usuario acceder a la plataforma. Esto
                  quiere decir que el usuario podrá ver y modificar información dentro de la
                  plataforma.
                </p>
              </div>
            </div>

            <Button fullWidth type="submit" onClick={handleSubmit} color="primary">
              Activar Cuenta
            </Button>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}
