import { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Image, Upload } from 'antd'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const ImagePreviewer = ({ initialValue, onChange }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (initialValue) {
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: initialValue
        }
      ])
    }

    return () => {
      setFileList([])
    }
  }
  , [initialValue])

  const handlePreview = async (file) => {
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file?.originFileObj)
    }
    setPreviewImage(file?.url || file?.preview)
    setPreviewOpen(true)
  }

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList([...newFileList])
    onChange(newFileList[0]?.originFileObj)
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Subir Imagen </div>
    </button>
  )
  return (
    <>
     {previewImage === '' && <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>}

      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage('')
          }}
          src={previewImage}
        />
      )}
    </>
  )
}
export default ImagePreviewer
