import { useEffect, useState } from 'react'
import S3Manager from 'src/services/S3Manager'

const s3Manager = new S3Manager()

export default function useGetS3ObjectUrl (path) {
  const [fileUrl, setFileUrl] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const response = await s3Manager.downloadFile(path)
      setFileUrl(response)
    }

    if (path) { fetchData() }
  }, [path])

  return { fileUrl }
}
