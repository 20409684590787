import useGetOrganizationProviders from '../../hooks/useGetOrganizationProviders'
import ProviderCard from './ProviderCard'

export default function ProviderTable({ handleOpen, handleEdit, handleOpenDelete }) {
  const { data: providers } = useGetOrganizationProviders()

  return (
    <>
      {providers?.map((provider) => (
        <ProviderCard
          key={provider.id}
          provider={provider}
          onOpen={handleOpen}
          onDelete={handleOpenDelete}
          onEdit={handleEdit}
        />
      ))}
      {(!providers || providers?.length === 0) && (
        <div className="flex items-center justify-center min-h-[480px]">
          <p className="text-xl text-center first:text-gray-400">
            No hay gestores de residuos cargados. <br />
            Por favor inserte almenos uno gestor.
          </p>
        </div>
      )}
    </>
  )
}
