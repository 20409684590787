import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/wastes')

export default function useDeleteWaste () {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => {
      return client.delete(id)
    },
    onError: (error) => {
      toast.error(`Error al eliminar la residuo, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['wastes'])
      toast.success('Residuo eliminada correctamente')
    }
  })
}
