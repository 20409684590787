import { Button } from '@nextui-org/react'
import useNavigation from 'src/pages/OnboardingPage/hooks/useNavigation'
import useCreateProviderForm from '../../hooks/useCreateProviderForm'
import CertificationForm from './CertificationsForm'
import ProviderCreateForm from './ProviderCreateForm'
import Navigation from './Navigation'
import WastesStep from './WastesStep'

export default function ProviderCreateModal({ onOpenChange }) {
  const { step, handleNextStep, handlePreviousStep, resetStep } = useNavigation()
  const { formik, loading, isFirstStepValid, isSecondStepValid } = useCreateProviderForm(
    onOpenChange,
    resetStep
  )
  const { handleSubmit } = formik

  return (
    <>
      <Navigation step={step} />
      <div className="min-h-[500px] pt-10">
        {step === 1 && <ProviderCreateForm formik={formik} />}
        {step === 2 && <CertificationForm formik={formik} />}
        {step === 3 && <WastesStep actionable={true} />}
      </div>
      <div className="flex gap-4 px-6">
        <Button
          fullWidth
          isDisabled={step === 1}
          variant="bordered"
          onPress={handlePreviousStep}
          color="primary"
          className="mt-4"
        >
          Atras
        </Button>
        {step !== 3 && (
          <Button
            fullWidth
            isDisabled={(step === 1 && !isFirstStepValid()) || (step === 2 && !isSecondStepValid())}
            variant="solid"
            onPress={handleNextStep}
            color="primary"
            className="mt-4"
          >
            Continuar
          </Button>
        )}
        {step === 3 && (
          <Button
            fullWidth
            isLoading={loading}
            variant="solid"
            onPress={handleSubmit}
            color="primary"
            className="mt-4"
          >
            Finalizar
          </Button>
        )}
      </div>
    </>
  )
}
