import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/pickups')

export default function useGetPickup ({ id }) {
  return useQuery({
    queryKey: ['pickups', id],
    queryFn: () => client.get(id),
    enabled: id !== undefined
  })
}
