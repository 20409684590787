import useGetProvider from 'src/hooks/useGetProvider'
import ProviderDetail from '../Providers/components/providers/ProviderDetail'
import { useSelector } from 'react-redux'
import ProviderEditModal from '../Providers/components/form/ProviderEditModal'
import CustomModal from 'src/components/CustomModal'
import { useDisclosure } from '@nextui-org/react'

export default function ProviderProfilePage() {
  const user = useSelector((state) => state.auth.user)
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onOpenChange: onOpenChangeEdit } = useDisclosure()

  const { data: provider } = useGetProvider(user.provider_id)

  console.log(provider)

  return (
    <div className="p-6">
      <ProviderDetail provider={provider} onEdit={onOpenEdit} />

      <CustomModal isOpen={isOpenEdit} onOpenChange={onOpenChangeEdit}>
        <ProviderEditModal provider={provider} onOpenChange={onOpenChangeEdit} />
      </CustomModal>
    </div>
  )
}
