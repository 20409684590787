import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/pickups/organization')

export default function useGetPickupsByOrganization({ page = 1, size = 100, traceability = undefined } = {}) {
  return useQuery({
    queryKey: ['pickups', 'organization', page, size, traceability],
    queryFn: () => client.getAll({
      page,
      size,
      traceability
    })
  })
}
