import { Button, Pagination, Switch, Tooltip, useDisclosure } from '@nextui-org/react'
import PickupsTable from './components/PickupsTable'
import { AiOutlinePlus } from 'react-icons/ai'
import CustomModal from 'src/components/CustomModal'
import CreatePickupForm from './components/CreatePickupForm'
import { fetchOrganizationBranchOffices } from 'src/store/features/organizationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import useGetPickupsByOrganization from 'src/hooks/useGetPickupsByOrganization'
import { Roles } from 'src/constants'
import useGetPickupsByProvider from 'src/hooks/useGetPickupsByProvider'

export default function PickupsPage() {
  const dispatch = useDispatch()
  const [withTraceability, setWithTraceability] = useState(false)
  const user = useSelector((s) => s.auth.user)
  const branchOffices = useSelector((s) => s.organizations.branchOffices)
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isFetching } =
    user.role === Roles.ORGANIZATION_ADMIN
      ? useGetPickupsByOrganization({ page: currentPage, size: 10, traceability: withTraceability })
      : useGetPickupsByProvider({ page: currentPage, size: 10, traceability: withTraceability })
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  useEffect(() => {
    dispatch(fetchOrganizationBranchOffices(user?.organization_id))
  }, [])

  return (
    <div className="flex flex-col gap-6 p-6  min-h-[500px]">
      <div className="flex justify-between items-center">
        <Switch isSelected={withTraceability} onValueChange={setWithTraceability}>
          Con Trazabilidad ({data?.items?.length})
        </Switch>
        <div className="flex items-center gap-6">
          <Pagination
            showControls
            total={data?.pages}
            page={currentPage}
            onChange={setCurrentPage}
          />
          {user.role === Roles.ORGANIZATION_ADMIN && (
            <>
              <div className="h-10 w-0.5 bg-gray-200 rounded-md" />
              <Tooltip content="Agregar un levante">
                <Button
                  isIconOnly
                  startContent={<AiOutlinePlus size={20} />}
                  color="primary"
                  className="self-end"
                  onPress={onOpen}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <PickupsTable user={user} data={data} isFetching={isFetching} branchOffices={branchOffices} />
      <CustomModal isOpen={isOpen} onOpenChange={onOpenChange}>
        <h1 className="text-primary text-2xl mt-4">Marcar un levante</h1>
        <CreatePickupForm onOpenChange={onOpenChange} branchOffices={branchOffices} />
      </CustomModal>
    </div>
  )
}
