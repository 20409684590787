import { createSlice } from '@reduxjs/toolkit'

const wastesSlice = createSlice({
  name: 'wastes',
  initialState: {
    wastes: []
  },
  reducers: {
    addWaste (state, action) {
      state.wastes.push(action.payload)
    },
    removeWaste (state, action) {
      state.wastes = state.wastes.filter((waste) => waste.id !== action.payload)
    }
  }
})

export const { addWaste, removeWaste } = wastesSlice.actions

export default wastesSlice.reducer
