import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/traceability/organization')

export default function useGetTraceabilityByOrganization () {
  return useQuery({
    queryKey: ['traceability'],
    queryFn: client.getAll
  })
}
