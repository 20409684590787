import { Button, Input, Select, SelectItem } from '@nextui-org/react'
import { Form, FormikProvider } from 'formik'
import { useSelector } from 'react-redux'
import ImagePreviewer from 'src/components/ImagePreview'
import { operationSectors } from 'src/constants'
import departments from 'src/data/departments.json'
import useGetCategories from 'src/hooks/useGetCategories'
import useGetLocations from 'src/hooks/useGetLocations'
import { motion } from 'framer-motion'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'
import useUpdateOrganization from '../hooks/useUpdateOrganization'

export default function TabOrganizationEdit () {
  const loading = useSelector((s) => s.organizations.loading)
  const userOrganization = useSelector((s) => s.organizations.userOrganization)
  const { locations } = useGetLocations(userOrganization?.department)
  const { fileUrl } = useGetS3ObjectUrl(userOrganization?.logo)
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    formik
  } = useUpdateOrganization(userOrganization)
  const { categories, subcategories } = useGetCategories(
    values.category?.values().next().value
  )

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.1,
        ease: 'easeInOut'
      }}
    >
      <div className="flex items-center justify-center mb-3">
        <ImagePreviewer
          initialValue={fileUrl}
          onChange={(file) => setFieldValue('logo', file)}
        />
      </div>
      <FormikProvider value={formik}>
        <Form
          className="flex flex-col mx-auto max-w-lg"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col gap-4 mb-6 items-center ">
            <Input
              {...getFieldProps('name')}
              size="sm"
              isInvalid={Boolean(touched.name && errors.name)}
              errorMessage={touched.name && errors.name}
              label="Nombre de la Empresa"
            />
            <Input
              {...getFieldProps('rut_number')}
              size="sm"
              isInvalid={Boolean(touched.rut_number && errors.rut_number)}
              errorMessage={touched.rut_number && errors.rut_number}
              label="Número de RUT"
            />
            <Select
              size="sm"
              label="Clasificación de Empresa"
              selectedKeys={values.category}
              onSelectionChange={(value) => setFieldValue('category', value)}
            >
              {categories?.map((c) => (
                <SelectItem key={c.name} value={c.name}>
                  {c.name}
                </SelectItem>
              ))}
            </Select>
            <Select
              isDisabled={values.category === ''}
              size="sm"
              label="Subclasificación"
              selectedKeys={values.subcategory}
              onSelectionChange={(value) => setFieldValue('subcategory', value)}
            >
              {subcategories?.map((sc) => (
                <SelectItem key={sc.name} value={sc.name}>
                  {sc.name}
                </SelectItem>
              ))}
            </Select>
            <Select
              size="sm"
              label="Areas Funcionales"
              selectedKeys={values.operation_sector}
              onSelectionChange={(value) =>
                setFieldValue('operation_sector', value)
              }
            >
              {operationSectors.map((l) => (
                <SelectItem
                  className=" whitespace-normal"
                  key={l.value}
                  value={l.value}
                >
                  {l.label}
                </SelectItem>
              ))}
            </Select>
            <Input
              {...getFieldProps('phone_number')}
              size="sm"
              isInvalid={Boolean(touched.phone_number && errors.phone_number)}
              errorMessage={touched.phone_number && errors.phone_number}
              label="Teléfono de Contacto"
            />
            <Select
              size="sm"
              label="Departamento"
              selectedKeys={values.department}
              onSelectionChange={(value) => setFieldValue('department', value)}
            >
              {departments?.map((l) => (
                <SelectItem key={l.value} value={l.value}>
                  {l.label}
                </SelectItem>
              ))}
            </Select>
            <Select
              size="sm"
              label="Localidad"
              selectedKeys={values.location}
              onSelectionChange={(value) => setFieldValue('location', value)}
            >
              {locations?.map((l) => (
                <SelectItem key={l.name} value={l.name}>
                  {`${l.name} (${l.code})`}
                </SelectItem>
              ))}
            </Select>
            <Input
              {...getFieldProps('address')}
              size="sm"
              isInvalid={Boolean(touched.address && errors.address)}
              errorMessage={touched.address && errors.address}
              label="Dirección"
            />
          </div>

          <Button
            fullWidth
            variant="solid"
            isDisabled={!formik.isValid}
            type="submit"
            isLoading={loading}
            color="primary"
          >
            Editar
          </Button>
        </Form>
      </FormikProvider>
    </motion.div>
  )
}
