import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import APIClient from 'src/services/apiClient'
import { decodeToken } from 'src/utils/jwt'

const apiClient = new APIClient('/login')
const apiClientSignup = new APIClient('/signup')
const apiClientVerify = new APIClient('/signup/verify-email')

const apiClientProvider = new APIClient('/signup/provider')

export const loginUser = createAsyncThunk('auth/login', async (formData, thunkAPI) => {
  try {
    const response = await apiClient.postForm(formData)
    localStorage.setItem('token', response.access_token)
    const userInfo = decodeToken(response.access_token)
    return { token: response.access_token, userInfo }
  } catch (err) {
    if (!err.response) {
      return thunkAPI.rejectWithValue({
        message: 'Ha ocurrido un error al comunicarse con el servidor.'
      })
    }

    return thunkAPI.rejectWithValue({ message: err.response?.data?.detail })
  }
})

export const signUpUser = createAsyncThunk('auth/signUp', async (formData, thunkAPI) => {
  try {
    const response = await apiClientSignup.post(formData)
    return response
  } catch (err) {
    if (!err.response) {
      return thunkAPI.rejectWithValue({
        message: 'ha ocurrido un error al comunicarse con el servidor.'
      })
    }

    return thunkAPI.rejectWithValue({ message: err.response?.data?.detail })
  }
})

export const signUpProvider = createAsyncThunk('auth/signUpProvider', async (formData, thunkAPI) => {
  try {
    const response = await apiClientProvider.post(formData)
    return response
  } catch (err) {
    if (!err.response) {
      return thunkAPI.rejectWithValue({
        message: 'ha ocurrido un error al comunicarse con el servidor.'
      })
    }

    return thunkAPI.rejectWithValue({ message: err.response?.data?.detail })
  }
})

export const verifyEmail = createAsyncThunk('auth/verify-email', async (token, thunkAPI) => {
  try {
    await apiClientVerify.post(token)
  } catch (err) {
    return thunkAPI.rejectWithValue({ message: err.response?.data?.detail })
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    loading: false,
    error: null
  },
  reducers: {
    logoutUser: (state, action) => {
      state.token = null
      state.user = null
      state.loading = false
    },
    assignOrganization: (state, action) => {
      state.user = { ...state.user, organization_id: action.payload }
    },
    assignProvider: (state, action) => {
      state.user = { ...state.user, provider_id: action.payload }
    },
    finishOnboarding: (state, action) => {
      state.user = { ...state.user, onboarding_completed: true }
    }
  },
  extraReducers (builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.token = action.payload.token
        state.user = action.payload.userInfo
        state.loading = false
        state.error = null
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(signUpUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(signUpProvider.pending, (state, action) => {
        state.loading = true
      })
      .addCase(signUpProvider.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(signUpProvider.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})

export const { logoutUser, assignOrganization, assignProvider, finishOnboarding } = authSlice.actions

export default authSlice.reducer
