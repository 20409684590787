import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'
import useAuthentication from '../hooks/useAuthentication'
import { isMobile } from 'react-device-detect'
import Logo from 'src/assets/logo-with-text.svg'

export default function SimpleLayout() {
  useAuthentication()

  if (isMobile) {
    return (
      <div className="bg-sky-50 flex flex-col items-center justify-center min-h-screen p-4">
        <h1 className="max-w-md text-3xl text-primary text-center">
          Reko todavía no está disponible para dispositivos móviles ni tablets. Por favor, accede
          desde un computador.
        </h1>
        <img src={Logo} alt="Reko" className="w-36 my-10" />
      </div>
    )
  }

  return (
    <div className="bg-sky-50 flex flex-col items-center justify-center min-h-screen">
      <Outlet />
      <Toaster richColors />
    </div>
  )
}
