import { jwtDecode } from 'jwt-decode'

export const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token)
    return decoded
  } catch (error) {
    console.error('Error decoding token:', error)
    return null
  }
}

export const tokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token)
    return decoded.exp * 1000 < Date.now()
  } catch (error) {
    console.error('Error decoding token:', error)
    return true
  }
}
