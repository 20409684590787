import {
  NavbarContent,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  DropdownSection
} from '@nextui-org/react'
import { FiLogOut } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { logoutUser } from 'src/store/features/authSlice'
import { useNavigate } from 'react-router-dom'
import { Routes, dropdownItems } from 'src/routes'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'

export default function ProfileDropdown({ user, logo }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { fileUrl } = useGetS3ObjectUrl(logo)
  const filteredDropdownItems = dropdownItems.filter((item) => item.roles.includes(user?.role))

  const handleLogout = () => {
    navigate(Routes.LOGIN)
    dispatch(logoutUser())
  }

  return (
    <NavbarContent as="div" justify="end">
      <Dropdown placement="bottom-end" variant="light">
        <DropdownTrigger>
          <Avatar src={fileUrl} isBordered as="button" size="md" />
        </DropdownTrigger>
        <DropdownMenu variant="flat">
          <DropdownSection aria-label="Profile Actions" showDivider>
            {filteredDropdownItems.map((item) => {
              return (
                <DropdownItem
                  key={item.label}
                  onClick={() => navigate(item.route)}
                  startContent={<item.IconComponent size={20} />}
                >
                  {item.label}
                </DropdownItem>
              )
            })}
          </DropdownSection>
          <DropdownItem
            key="logout"
            onClick={handleLogout}
            startContent={<FiLogOut size={20} />}
            color="danger"
          >
            Cerrar Sesión
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </NavbarContent>
  )
}
