import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/wastes')

export default function useGetWastesByProvider (providerId) {
  return useQuery({
    queryKey: ['wastes', providerId],
    queryFn: () => client.get(`provider/${providerId}`),
    enabled: providerId !== undefined
  })
}
