import { Button, Tooltip } from '@nextui-org/react'
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5'

export default function MinimizeButton ({ minimized, toggleMinimized }) {
  return (
    <Tooltip content={minimized ? 'Expandir' : 'Minimizar'}>
      <Button
        isIconOnly
        variant="flat"
        className={`${minimized ? '' : 'w-full'} self-center`}
        size="sm"
        onClick={toggleMinimized}
      >
        {minimized ? <IoChevronForwardOutline /> : <IoChevronBackOutline />}
      </Button>
    </Tooltip>
  )
}
