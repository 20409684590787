export default function translate (word) {
  switch (word) {
    case 'insurance':
      return 'Seguro'
    case 'bps':
      return 'BPS'
    case 'dgi':
      return 'DGI'
    case 'iso':
      return 'ISO'
    case 'b_company':
      return 'Empresa B'
    case 'gptw':
      return 'Great Place to Work'
    case 'sustentability':
      return 'Sustentabilidad'
    case 'great_place_to_work':
      return 'Great Place to Work'
    case 'sustentability_commitment':
      return 'Compromiso de Sustentabilidad'
    case 'vehicle':
      return 'Vehículo'
    case 'solid_waste':
      return 'Residuos Sólidos'
    case 'montevideo':
      return 'Montevideo'
    case 'canelones':
      return 'Canelones'
    case 'cerro_largo':
      return 'Cerro Largo'
    case 'colonia':
      return 'Colonia'
    case 'durazno':
      return 'Durazno'
    case 'flores':
      return 'Flores'
    case 'florida':
      return 'Florida'
    case 'lavalleja':
      return 'Lavalleja'
    case 'maldonado':
      return 'Maldonado'
    case 'paysandu':
      return 'Paysandú'
    case 'admin':
      return 'Administrador'
    case 'organization_admin':
      return 'Admin de Organización'
    case 'provider_admin':
      return 'Admin de Proveedor'
    case 'user':
      return 'Usuario'
    case 'recycling':
      return 'Reciclaje'
    case 'reuse':
      return 'Reutilización'
    case 'final_disposal':
      return 'Disposición final'
    default:
      return word
  }
}
