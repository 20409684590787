import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/providers')

export default function useGetProviders ({ unassigned = undefined } = {}) {
  return useQuery({
    queryKey: ['all_providers'],
    queryFn: () => client.getAll(
      { unassigned }
    )
  })
}
