import { GoDotFill } from 'react-icons/go'
import { Button } from '@nextui-org/react'

export default function Navigation ({
  step,
  onPreviousStep,
  onNextStep,
  children
}) {
  // const previousAvailableSteps = [2, 3, 4, 5]
  const nextAvailableSteps = [1, 5]

  // const isPreviousDisabled = !previousAvailableSteps.includes(step)
  const isNextDisabled = !nextAvailableSteps.includes(step)

  return (
    <div className="rounded-md flex gap-2 justify-between items-center">
      {/* <Button
        isIconOnly
        variant="light"
        color="default"
        onPress={onPreviousStep}
        isDisabled={isPreviousDisabled}
      >
        <IoChevronBack />
      </Button> */}
      <div className=" flex gap-2">
        {[1, 2, 3, 4].map((s) => (
          <GoDotFill
            key={s}
            size={20}
            className={`text-xl ${
              s === step ? 'text-primary' : 'text-gray-400'
            }`}
          />
        ))}
      </div>
      {children || (
        <Button
          variant="bordered"
          color="primary"
          onPress={onNextStep}
          className="px-10"
          isDisabled={isNextDisabled}
        >
          Continuar
        </Button>
      )}
    </div>
  )
}
