import { Modal, ModalContent, ModalHeader, ModalBody } from '@nextui-org/react'
import ManagedWastesForm from './WastesForm'

export default function WasteAddModal ({ isOpen, onOpenChange, provider }) {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent className='py-4'>
        <ModalHeader className="flex flex-col gap-1">Nueva Residuo</ModalHeader>
        <ModalBody>
          <ManagedWastesForm onOpenChange={onOpenChange} provider={provider}/>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
