import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/users')

export default function useActivateUserAccount (onOpenChange) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => {
      return client.put(id + '/activate')
    },
    onError: (error) => {
      toast.error(`Error al activar la cuenta, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['users'])
      toast.success('Cuenta activada correctamente')
      onOpenChange()
    }
  })
}
