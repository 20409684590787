import ChangePasswordForm from './components/ChangePasswordForm'

export default function ConfigurationPage() {
  return (
    <div className="p-6 ">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col">
          <h2 className="text-xl">Actualizar Contraseña</h2>
          <p className="text-sm text-gray-400">
            Actualiza tu contraseña regularmente para mantener tu cuenta segura.
          </p>
        </div>
        <ChangePasswordForm />
      </div>
    </div>
  )
}
