import { Divider } from '@nextui-org/react'
import InfoBox from './components/InfoBox'
import TraceabilityNotifier from './components/TraceabilityNotifier'
import { useSelector } from 'react-redux'
import useGetOrganizationProviders from '../Providers/hooks/useGetOrganizationProviders'
import useGetPickupsByOrganization from 'src/hooks/useGetPickupsByOrganization'
import useGetPickupsByProvider from 'src/hooks/useGetPickupsByProvider'
import { Roles } from 'src/constants'
import useGetWastesByOrganization from '../Providers/hooks/useGetWastesByOrganization'

export default function DashboardPage() {
  const user = useSelector((state) => state.auth.user)
  const { data: providers } = useGetOrganizationProviders()
  const { data: pickups } =
    user?.role === Roles.PROVIDER_ADMIN ? useGetPickupsByProvider() : useGetPickupsByOrganization()
  const { data: wastes } = useGetWastesByOrganization(user?.organization_id)

  const { data: pickupsWithoutTraceability } =
    user?.role === Roles.PROVIDER_ADMIN
      ? useGetPickupsByProvider({ traceability: false })
      : useGetPickupsByOrganization({ traceability: false })
  const { data: pickupsWithTraceability } =
    user?.role === Roles.PROVIDER_ADMIN
      ? useGetPickupsByProvider({ traceability: true })
      : useGetPickupsByOrganization({ traceability: true })

  return (
    <div className="flex flex-col gap-6 p-6 ">
      <h1 className="text-2xl font-semibold text-slate-900">Hola, bienvenido de nuevo.</h1>
      <div className="flex flex-col gap-3 border-1 border-gray-200 p-6 rounded-2xl">
        <div className="flex justify-between">
          <h2 className="text-xl text-gray-600">Vista general</h2>
        </div>
        <Divider />
        <div className="flex gap-6">
          {user?.role === Roles.ORGANIZATION_ADMIN && (
            <InfoBox title="Proovedores" value={providers?.length} />
          )}
          {user?.role === Roles.ORGANIZATION_ADMIN && (
            <InfoBox title="Residuos Gestionados" value={wastes?.length} />
          )}
          <InfoBox title="Recolecciones" value={pickups?.items?.length} divider={false} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <TraceabilityNotifier data={pickupsWithoutTraceability?.items} />
        <TraceabilityNotifier traceability data={pickupsWithTraceability?.items} />
      </div>
    </div>
  )
}
