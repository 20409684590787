import { Button, Input, Link } from '@nextui-org/react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import UserSchema from 'src/schemas/User'
import { toast } from 'sonner'
import { signUpUser } from 'src/store/features/authSlice'

export default function SignUpForm({ setSelected }) {
  const dispatch = useDispatch()
  const loading = useSelector((s) => s.auth.loading)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      name: '',
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (values) => {
      const data = { ...values }
      delete data.passwordConfirmation

      try {
        await dispatch(signUpUser(data)).unwrap()
        toast.success(
          'Pre-registro creado exitosamente. Por favor, compruebe su casilla de correo para activar su cuenta.'
        )
        setSelected('login')
      } catch (error) {
        toast.error(`Error al crear la cuenta, ${error.message}`)
      }
    }
  })

  const { errors, touched, getFieldProps, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <Input
          isRequired
          {...getFieldProps('email')}
          size="sm"
          isInvalid={Boolean(touched.email && errors.email)}
          errorMessage={touched.email && errors.email}
          label="Email"
        />
        <Input
          isRequired
          {...getFieldProps('name')}
          size="sm"
          isInvalid={Boolean(touched.name && errors.name)}
          errorMessage={touched.name && errors.name}
          label="Nombre del responsable"
        />
        <Input
          isRequired
          {...getFieldProps('password')}
          size="sm"
          isInvalid={Boolean(touched.password && errors.password)}
          errorMessage={touched.password && errors.password}
          label="Contraseña"
          type="password"
        />
        <Input
          isRequired
          {...getFieldProps('passwordConfirmation')}
          size="sm"
          isInvalid={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
          errorMessage={touched.passwordConfirmation && errors.passwordConfirmation}
          label="Repetir Contraseña"
          type="password"
        />
        <p className="text-center text-small pt-1">
          ¿Ya tienes una cuenta?{' '}
          <Link as="button" size="sm" onPress={() => setSelected('login')}>
            Iniciar Sesión
          </Link>
        </p>
        <Button
          fullWidth
          isDisabled={!formik.isValid}
          isLoading={loading}
          type="submit"
          color="primary"
        >
          Registrarse
        </Button>
      </Form>
    </FormikProvider>
  )
}
