import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL
})

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use((response) => response, (error) => {
  // if (error?.response?.status === 401) {
  //   localStorage.removeItem('token')
  //   window.location.href = '/login'
  // }
  return Promise.reject(error)
})

class APIClient {
  endpoint

  constructor (endpoint) {
    this.endpoint = endpoint
  }

  getAll = (params) => {
    return axiosInstance.get(this.endpoint, { params }).then(res => res.data)
  }

  get = (id) => {
    return axiosInstance.get(this.endpoint + '/' + id).then(res => res.data)
  }

  post = (data) => {
    return axiosInstance.post(this.endpoint, data).then(res => res.data)
  }

  postWithId = (id, data) => {
    return axiosInstance.post(this.endpoint + '/' + id, data).then(res => res.data)
  }

  postForm = (formData) => {
    return axiosInstance.post(this.endpoint, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data)
  }

  put = (id, data) => {
    return axiosInstance.put(this.endpoint + '/' + id, data).then(res => res.data)
  }

  delete = (id) => {
    return axiosInstance.delete(this.endpoint + '/' + id).then(res => res.data)
  }
}

export default APIClient
