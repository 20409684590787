import { Button, Tooltip, useDisclosure } from '@nextui-org/react'
import { AiOutlinePlus } from 'react-icons/ai'
import CustomModal from 'src/components/CustomModal'
import useGetTraceabilityByOrganization from './hooks/useGetTraceabilityByOrganization'
import TraceabilityTable from './components/TraceabilityTable'
import CreateTraceabilityForm from './components/CreateTraceabilityForm'
import { useSelector } from 'react-redux'
import useGetTraceabilityByProvider from './hooks/useGetTraceabilityByProvider'
import { Roles } from 'src/constants'

export default function TraceabilityPage() {
  const user = useSelector((state) => state.auth.user)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  const { data: traceability, isFetching } =
    user?.role === Roles.ORGANIZATION_ADMIN
      ? useGetTraceabilityByOrganization()
      : useGetTraceabilityByProvider()

  return (
    <div className="flex flex-col gap-6 p-4  min-h-[500px]">
      <Tooltip content="Agregar una trazabilidad">
        <Button
          isIconOnly
          startContent={<AiOutlinePlus size={20} />}
          color="primary"
          className="self-end"
          onPress={onOpen}
        />
      </Tooltip>

      <TraceabilityTable traceability={traceability} loading={isFetching} />

      <CustomModal isOpen={isOpen} onOpenChange={onOpenChange}>
        <CreateTraceabilityForm onOpenChange={onOpenChange} />
      </CustomModal>
    </div>
  )
}
