import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/wastes/organization')

export default function useGetWastesByOrganization (organization) {
  return useQuery({
    queryKey: ['wastes', organization],
    queryFn: () => client.get(organization),
    enabled: organization != null
  })
}
