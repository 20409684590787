import { useDisclosure } from '@nextui-org/react'
import ProviderCreateModal from '../Providers/components/form/ProviderCreateModal'

export default function OnboardingProviderPage() {
  const { onOpenChange } = useDisclosure()

  return (
    <div className="bg-white rounded-2xl shadow-md my-4 pb-8 max-w-xl">
      <ProviderCreateModal backdrop="transparent" isOpen={true} onOpenChange={onOpenChange} />
    </div>
  )
}
