import { Button, Divider, useDisclosure } from '@nextui-org/react'
import { AiOutlinePlus } from 'react-icons/ai'
import WasteAddModal from './WasteAddModal'
import { useSelector } from 'react-redux'
import WasteCard from '../WasteCard'
import useGetWastesByProvider from '../../hooks/useGetWastesByProvider'

export default function WastesStep({ provider, actionable, onConfirm }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const { data: wastes2 } = useGetWastesByProvider(provider?.id)
  const wastes = provider ? wastes2 : useSelector((state) => state.wastes.wastes)

  return (
    <div className="flex flex-col w-full gap-3 p-6 justify-center items-center ">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <h1>Residuos que gestionan</h1>
          <Button
            onPress={onOpen}
            variant="light"
            color="primary"
            startContent={<AiOutlinePlus size={20} />}
          >
            Agregar Residuo
          </Button>
        </div>
        <Divider className="mt-2" />
      </div>
      {wastes?.length === 0 && <p className="text-gray-400">No hay residuos gestionados.</p>}
      {wastes?.map((waste, index) => (
        <WasteCard key={index} waste={waste} actionable={actionable} provider={provider} />
      ))}

      <WasteAddModal isOpen={isOpen} onOpenChange={onOpenChange} provider={provider} />
    </div>
  )
}
