import { array, date, object, string } from 'yup'

export const PickupSchema = object().shape({
  pickup_date: date().required('Fecha de recolección requerida'),
  provider_id: string().required('Proveedor requerido'),
  certificate_number: string().required('Número de constancia requerido'),
  vehicle_registration_number: string().required('Matrícula del vehículo requerida'),
  // cost: number().required('Costo de recolección requerido'),
  wastes: array().min(1, 'Selecciona al menos un tipo de residuo')
})
