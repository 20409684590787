import OrganizationsTable from './components/OrganizationsTable'
import useGetOrganizations from './hooks/useGetOrganizations'

export default function OrganizationsPage() {
  const { data: organizations, isFetching } = useGetOrganizations()

  return (
    <div className="flex flex-col gap-4 p-4">
      <OrganizationsTable organizations={organizations} loading={isFetching} />
    </div>
  )
}
