import { motion } from 'framer-motion'
import { useEffect } from 'react'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AnimationCheck from 'src/assets/lottie-check.json'
import { Routes } from 'src/routes'
import { completeOnboarding } from 'src/store/features/organizationsSlice'

export default function OnboardingConfirmation() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((s) => s.auth.user)

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(completeOnboarding(user.organization_id))
      navigate(Routes.HOME)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <motion.div
      className="flex flex-col h-full gap-5 mb-5 items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.1,
        ease: 'easeInOut'
      }}
    >
      <h1 className="text-3xl text-center text-primary">Registro Finalizado</h1>
      <Lottie
        height={250}
        width={250}
        options={{
          animationData: AnimationCheck,
          loop: false,
          autoplay: true
        }}
      />
      <p className="text-md text-gray-400 text-center max-w-sm">
        Gracias por formar parte de Reko, desde ahora puedes empezar a utilizar nuestra aplicación y
        disfrutar de todas las funcionalidades que tenemos para ti.
      </p>
    </motion.div>
  )
}
