import { Button } from '@nextui-org/react'
import { IoMdTrash } from 'react-icons/io'
import { LuRecycle } from 'react-icons/lu'
import { useDispatch } from 'react-redux'
import { removeWaste } from 'src/store/features/wasteSlice'
import useDeleteWaste from '../hooks/useDeleteWaste'

export default function WasteCard({ waste, provider, actionable = false }) {
  const dispatch = useDispatch()
  const { mutate } = useDeleteWaste()

  const handleDelete = () => {
    if (provider) {
      mutate({ id: waste.id })
    } else {
      dispatch(removeWaste(waste.id))
    }
  }

  return (
    <div
      key={waste.id}
      className="w-full flex items-center justify-between bg-gray-50 p-3 rounded-md"
    >
      <div className="flex gap-4 items-center">
        <LuRecycle className="text-primary w-[30px]" size={20} />
        <div className="flex flex-col">
          <p className="text-sm max-w-sm mb-1 line-clamp-2">
            ({waste.category_code}) {waste.category_name}
          </p>
          <p className="text-xs max-w-sm text-gray-500 line-clamp-4">
            ({waste.subcategory_code}) {waste.subcategory_name}
          </p>
        </div>
      </div>
      {actionable && (
        <Button onPress={handleDelete} isIconOnly color="danger" variant="light" size="sm">
          <IoMdTrash size={20} />
        </Button>
      )}
    </div>
  )
}
