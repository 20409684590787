import { Button } from '@nextui-org/react'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from 'react-router-dom'
import { Routes } from '../routes'

const ErrorPage = () => {
  const error = useRouteError()
  const navigate = useNavigate()

  return (
      <div className='max-w-[1200px] min-h-screen flex flex-col gap-8 items-center justify-center mx-auto'>
        <h1 className='text-7xl text-primary'>Oops!</h1>
        <p className='text-2xl'>
          {isRouteErrorResponse(error)
            ? 'Esta página no existe. Vuelve a la página de inicio.'
            : 'Ha ocurrido un error inesperado. Vuelve a intentarlo más tarde.'}
        </p>
        <Button color='primary' className='mt-2' onClick={() => navigate(Routes.HOME)}>Ir al Inicio</Button>
      </div>
  )
}

export default ErrorPage
