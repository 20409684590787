import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/providers')

export default function useGetProviders () {
  return useQuery({
    queryKey: ['providers'],
    queryFn: client.getAll
  })
}
