import { Button, Tooltip } from '@nextui-org/react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function NavButton ({ label, route, IconComponent, minimized }) {
  const navigate = useNavigate()
  const location = useLocation()

  const isActiveRoute = location.pathname.split('/')[1] === route.split('/')[1]

  if (minimized) {
    return (
      <Tooltip content={label} placement="right">
        <Button
          isIconOnly
          onClick={() => navigate(route)}
          variant={isActiveRoute ? 'solid' : 'light'}
          color={isActiveRoute ? 'primary' : 'default'}
          className="flex gap-2 w-full rounded-xl h-12 items-center"
        >
          {<IconComponent />}
        </Button>
      </Tooltip>
    )
  }

  return (
    <Button
      onClick={() => navigate(route)}
      variant={isActiveRoute ? 'solid' : 'light'}
      color={isActiveRoute ? 'primary' : 'default'}
      startContent={<IconComponent className="w-5" />}
    >
      <span className="w-full text-start text-xs tracking-wide">
        {label}
      </span>
    </Button>
  )
}
