import React from 'react'
import WasteCard from 'src/pages/Providers/components/WasteCard'

export default function PickupDetailCard({ pickup }) {
  if (!pickup) return null

  return (
    <div className="flex flex-col gap-4 p-4 border-1 border-gray-200 rounded-xl">
      <h1 className="text-xs uppercase text-primary tracking-wide font-bold">
        Información sobre esta recolección
      </h1>
      <div className="flex flex-col gap-2 text-sm text-gray-500">
        <div className="flex gap-4">
          <span>Detalle:</span>
          <span>{pickup.detail}</span>
        </div>
        <div className="flex gap-4">
          <span>Fecha de recolección:</span>
          <span>{pickup.pickup_date}</span>
        </div>
        <div className="flex gap-4">
          <span>Número de certificado:</span>
          <span>{pickup.certificate_number}</span>
        </div>
        <div className="flex gap-4">
          <span>Vehículo:</span>
          <span>{pickup.vehicle_registration_number}</span>
        </div>
        <div className="flex gap-4">
          <span>Proveedor:</span>
          <span>{pickup.provider.name}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <span className="pl-1 text-xs text-gray-400">Residuos Recolectados</span>
        {pickup.wastes.map((waste) => (
          <div className="flex gap-2" key={waste.id}>
            <WasteCard waste={waste} />
          </div>
        ))}
      </div>
    </div>
  )
}

// {

//     created_at: '2024-06-17T12:41:02.691999',

//     updated_at: '2024-06-17T12:41:02.692011',

//     id: 'f5973daa-5bad-4aad-afc6-8bc9fb51ae64',

//     pickup_date: '2024-06-30',

//     provider_id: '67109eee-e3d5-4210-a714-0149ae677a40',

//     branch_office_id: '17f2e348-6263-4469-be4b-9fdbbd69c865',

//     certificate_number: '321321',

//     vehicle_registration_number: 'ABC123',

//     cost: null,

//     detail: 'Extracción ',

//     provider: {

//       logo: 'logos/67109eee-e3d5-4210-a714-0149ae677a40/logo.jpg',

//       location: 'cerro chato',

//       name: 'Tata',

//       address: '18 de Julio 1431',

//       rut_number: '212321',

//       woman_in_leadership_positions: null,

//       deleted_at: null,

//       type: 'private',

//       gender_equity_policy: null,

//       created_at: '2024-06-17T12:34:35.747334',

//       phone_number: '091657062',

//       inclusion_programs: 'Lorem ipsum',

//       email: 'tata@gmail.com',

//       fleet: 'hibrid',

//       updated_at: '2024-06-17T12:34:35.747345',

//       responsible_name: 'Paula Rodriguez',

//       footprint_reduction_programs: null,

//       id: '67109eee-e3d5-4210-a714-0149ae677a40',

//       department: 'paysandu',

//       organization_id: '7876da76-2b0a-4b1d-8221-204eedf2b0bc'

//     },

//     wastes: [

//       {

//         id: '7caf494f-22fc-400f-97cc-e3a34a8f0f4d',

//         type: '2',

//         category_code: '1079',

//         subcategory_code: '107903',

//         category_name: 'Elaboración de otros productos alimenticios',

//         deleted_at: null,

//         subcategory_name: 'Residuos de conservantes',

//         provider_id: '67109eee-e3d5-4210-a714-0149ae677a40'

//       }

//     ]

//   }
