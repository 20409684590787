import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { tokenExpired } from 'src/utils/jwt'
import { logoutUser } from 'src/store/features/authSlice'
import { Routes, menuItems, dropdownItems } from 'src/routes'
import { Roles } from 'src/constants'

const useAuthentication = () => {
  const token = useSelector((state) => state.auth.token)
  const { user } = useSelector((state) => state.auth)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!token) {
      navigate(Routes.LOGIN)
    }

    if (token && tokenExpired(token)) {
      dispatch(logoutUser)
      navigate(Routes.LOGIN)
    }

    if (token && !tokenExpired(token) && user && !user.onboarding_completed) {
      navigate(Routes.ONBOARDING)
    }

    if (token && !tokenExpired(token) && user && user.role === Roles.PROVIDER_ADMIN && !user.provider_id) {
      navigate(Routes.ONBOARDING_PROVIDER)
    }
  }, [token, user])

  useEffect(() => {
    const activeRoute = location.pathname.split('/')[1]
    const routePermissions = menuItems.filter((item) => item.route === `/${activeRoute}`)?.[0]?.roles
    const routePermissions2 = dropdownItems.filter((item) => item.route === `/${activeRoute}`)?.[0]?.roles

    if ((token && !tokenExpired(token) && user && user.role !== Roles.PROVIDER_ADMIN && user.onboarding_completed) ||
    (token && !tokenExpired(token) && user && user.role === Roles.PROVIDER_ADMIN && user.provider_id)) {
      if (!routePermissions?.includes(user?.role) && !routePermissions2?.includes(user?.role)) {
        switch (user.role) {
          case Roles.ADMIN:
            navigate(Routes.USERS)
            break
          case Roles.ORGANIZATION_ADMIN:
            navigate(Routes.HOME)
            break
          case Roles.PROVIDER_ADMIN:
            navigate(Routes.HOME)
            break
          case Roles.USER:
            navigate(Routes.HOME)
            break
          default:
            navigate(Routes.HOME)
        }
      }
    }
  }, [location.pathname, user])
}

export default useAuthentication
