import { Image } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LogoLeftText from 'src/assets/logo-left-text.svg'
import Logo from 'src/assets/logo.svg'
import { fetchUserOrganization } from 'src/store/features/organizationsSlice'
import MinimizeButton from './MinimizeButton'
import NavButton from './NavButton'
import { menuItems } from 'src/routes'

export default function Navbar({ minimized, toggleMinimized }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const filteredMenuItems = menuItems.filter((item) => item.roles.includes(user?.role))

  useEffect(() => {
    if (user?.organization_id) {
      dispatch(fetchUserOrganization(user?.organization_id))
    }
  }, [])

  return (
    <motion.div
      animate={{
        width: '100%',
        margin: '0px',
        height: '100%'
      }}
      className="relative flex flex-col gap-6 mins-h-screen px-3 py-2 bg-gray-100"
    >
      <div className="flex ">
        <Image src={minimized ? Logo : LogoLeftText} className="w-full max-w-[120px] max-h-16" />
      </div>
      <div className="flex flex-col gap-3">
        {filteredMenuItems.map((item, index) => (
          <NavButton
            key={`${item}-${index}`}
            label={item.label}
            route={item.route}
            IconComponent={item.IconComponent}
            minimized={minimized}
          />
        ))}
      </div>
      <MinimizeButton minimized={minimized} toggleMinimized={toggleMinimized} />
    </motion.div>
  )
}
