import { Form, FormikProvider, useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import { BranchOfficeSchema } from 'src/schemas/BranchOffice'
import { Button, Input, Select, SelectItem } from '@nextui-org/react'
import {
  createBranchOffice,
  fetchOrganizationBranchOffices
} from 'src/store/features/organizationsSlice'
import useGetLocations from 'src/hooks/useGetLocations'
import departments from 'src/data/departments.json'

export default function CreateBranchOfficeForm({ onOpenChange }) {
  const dispatch = useDispatch()
  const user = useSelector((s) => s.auth.user)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      department: new Set([]),
      location: new Set([]),
      address: '',
      responsible_name: '',
      phone_number: '',
      email: ''
    },
    validationSchema: BranchOfficeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = {
          ...values,
          department: values.department.values().next().value,
          location: values.location.values().next().value
        }

        await dispatch(
          createBranchOffice({ ...data, organization_id: user.organization_id })
        ).unwrap()
        dispatch(fetchOrganizationBranchOffices(user.organization_id))
        toast.success('Sucursal creada correctamente')
        onOpenChange()
        resetForm()
      } catch (error) {
        toast.error(`Error al crear el sucursal, ${error.error}`)
      }
    }
  })
  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue } = formik

  const { locations } = useGetLocations(values.department?.values().next().value)

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <Select
          size="sm"
          label="Departamento"
          selectedKeys={values.department}
          onSelectionChange={(value) => setFieldValue('department', value)}
        >
          {departments?.map((l) => (
            <SelectItem key={l.value} value={l.value}>
              {l.label}
            </SelectItem>
          ))}
        </Select>
        <Select
          size="sm"
          label="Localidad"
          selectedKeys={values.location}
          onSelectionChange={(value) => setFieldValue('location', value)}
        >
          {locations?.map((l) => (
            <SelectItem key={l.name} value={l.name}>
              {`${l.name} (${l.code})`}
            </SelectItem>
          ))}
        </Select>
        <Input
          {...getFieldProps('address')}
          size="sm"
          error={Boolean(touched.address && errors.address)}
          helperText={touched.address && errors.address}
          label="Dirección"
        />
        <Input
          {...getFieldProps('responsible_name')}
          size="sm"
          error={Boolean(touched.responsible_name && errors.responsible_name)}
          helperText={touched.responsible_name && errors.responsible_name}
          label="Nombre del responsable"
        />
        <Input
          {...getFieldProps('email')}
          size="sm"
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          label="Email"
        />
        <Input
          {...getFieldProps('phone_number')}
          size="sm"
          error={Boolean(touched.phone_number && errors.phone_number)}
          helperText={touched.phone_number && errors.phone_number}
          label="Teléfono de Contacto"
        />
        <Button
          fullWidth
          isDisabled={!formik.isValid}
          type="submit"
          color="primary"
          className="mt-2"
        >
          Agregar sucursal
        </Button>
      </Form>
    </FormikProvider>
  )
}
