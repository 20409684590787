import useSendTraceabilityReminder from '../hooks/useSendTraceabilityReminder'
import TraceabilityCard from './TraceabilityCard'

export default function TraceabilityNotifier({ traceability = false, data }) {
  const { mutate } = useSendTraceabilityReminder()

  const sendReminder = (id) => {
    mutate({ providerId: id })
  }

  return (
    <div className="flex flex-col gap-3 border-1 border-gray-200 p-6 rounded-2xl min-h-[400px]">
      <div className="flex gap-2 items-center">
        <h3 className="text-lg font-semibold">Trazabilidad</h3>
        <span className={`${traceability ? 'text-green-400' : 'text-red-400'} font-bold`}>
          {traceability ? 'confirmada' : 'pendiente'}
        </span>
      </div>

      <div className="flex flex-col gap-1">
        {data?.map((item) => (
          <TraceabilityCard
            key={item.id}
            item={item}
            traceability={traceability}
            onPressReminder={sendReminder}
          />
        ))}
      </div>
    </div>
  )
}
