import { Button, Tooltip } from '@nextui-org/react'
import { IoTrashOutline } from 'react-icons/io5'
import translate from 'src/utils/translate'

export default function TraceabilityItemCard({ item, pickup, onDelete }) {
  if (!item) return null

  const waste = pickup.wastes.find((w) => w.waste_id === item.id)

  return (
    <div className="w-full flex items-center justify-between bg-gray-50 p-4 rounded-md">
      <div className="flex gap-4 items-center">
        <div className="flex flex-col">
          <p className="text-sm max-w-sm mb-1 line-clamp-2">{`(${waste?.category_code}) ${waste?.category_name}`}</p>
          <p className="text-xs max-w-sm text-gray-500 line-clamp-4">
            {`${item.weight}${item.unit} - ${translate(item.final_destination)}`}
          </p>
        </div>
      </div>
      <Tooltip content="Eliminar">
        <Button onPress={() => onDelete(item.waste_id)} isIconOnly variant="light" color="danger">
          <IoTrashOutline />
        </Button>
      </Tooltip>
    </div>
  )
}
