import { array, number, object, string } from 'yup'

export const TraceabilitySchema = object().shape({
  pickup_id: string().required('Recolección requerida'),
  items: array().required('Items requeridos')

})

export const TraceabilityItemSchema = object().shape({
  waste_id: string().required('Tipo de residuo requerido'),
  weight: number().required('Peso requerido').positive('El peso debe ser mayor a 0'),
  unit: string().required('Unidad de peso requerida'),
  final_destination: string().required('Destino final requerido')
})
