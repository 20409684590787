import { object, string } from 'yup'

export const ProviderSchema = object().shape({
  name: string().required('El nombre es requerido'),
  rut_number: string().required('El RUT es requerido'),
  type: string().required('El tipo de empresa es requerido'),
  phone_number: string().required('El teléfono es requerido'),
  email: string().email('El email no es válido').required('El email es requerido'),
  responsible_name: string().required('El nombre del responsable es requerido'),
  department: string().required('El departamento es requerido'),
  location: string().required('La localidad es requerida'),
  address: string().required('La dirección es requerida'),
  fleet: string().required('La flota es requerida'),
  dgi_certificate: object().shape({
    expiration_date: string().required('La fecha de vencimiento es requerida')
  }),
  bps_certificate: object().shape({
    expiration_date: string().required('La fecha de vencimiento es requerida')
  }),
  insurance_certificate: object().shape({
    expiration_date: string().required('La fecha de vencimiento es requerida')
  })

})
