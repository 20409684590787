import { useFormik } from 'formik'
import { useState } from 'react'
import useNavigation from 'src/pages/OnboardingPage/hooks/useNavigation'
import { TraceabilitySchema } from 'src/schemas/Traceability'
import useCreateTraceability from '../hooks/useCreateTraceability'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

export default function CreateTraceabilityForm({ onOpenChange }) {
  const [selectedPickup, setSelectedPickup] = useState(null)
  const { mutate } = useCreateTraceability(onOpenChange)
  const { step, handleNextStep, handlePreviousStep } = useNavigation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickup_id: new Set([]),
      items: []
    },
    validationSchema: TraceabilitySchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        pickup_id: values.pickup_id.values().next().value
      }
      mutate({ data })
    }
  })

  return (
    <div className="flex flex-col w-full gap-6">
      <h1 className="text-2xl text-primary">Crear Trazabilidad</h1>
      {step === 1 && (
        <StepOne
          selectedPickup={selectedPickup}
          setSelectedPickup={setSelectedPickup}
          formik={formik}
          onNextStep={handleNextStep}
        />
      )}
      {step === 2 && (
        <StepTwo
          selectedPickup={selectedPickup}
          formik={formik}
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
        />
      )}
    </div>
  )
}
