import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/organizations')

export default function useGetOrganization (id) {
  return useQuery({
    queryKey: ['organizations', id],
    queryFn: () => client.get(id)
  })
}
