import Logo from 'src/assets/logo.svg'
import Navigation from './Navigation'

export default function OnboardingWelcome ({ onNextStep }) {
  return (
    <div className="flex flex-col gap-8 h-full">
      <img
        src={Logo}
        width={100}
        alt="Welcome"
      />
      <div className='h-full'>
        <h1 className="text-6xl text-primary pb-4">Validación <br/> exitosa.</h1>
        <p className="text-3xl text-gray-400">Continúa con el registro.</p>
      </div>
      <Navigation
        step={1}
        onNextStep={onNextStep}
      />
    </div>
  )
}
