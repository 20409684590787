import { useState } from 'react'

export default function useNavigation () {
  const [step, setStep] = useState(1)

  const handleNextStep = () => {
    setStep((prev) => prev + 1)
  }

  const handlePreviousStep = () => {
    setStep((prev) => prev - 1)
  }

  const resetStep = () => {
    setStep(1)
  }

  return {
    step,
    handleNextStep,
    handlePreviousStep,
    resetStep
  }
}
