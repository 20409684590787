import {
  Button,
  Chip,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure
} from '@nextui-org/react'
import { useState } from 'react'
import { IoCreateOutline, IoTrashOutline } from 'react-icons/io5'
import TraceabilityDeleteModal from './TraceabilityDeleteModal'
import translate from 'src/utils/translate'
import formatDate from 'src/utils/date'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Roles } from 'src/constants'
import CustomModal from 'src/components/CustomModal'
import UpdateTraceabilityForm from './UpdateTraceabilityForm'

const columns = [
  { key: 'responsible', label: 'RESPONSABLE' },
  {
    key: 'created_at',
    label: 'FECHA DE REGISTRO'
  },
  {
    key: 'pickup',
    label: 'DETALLE DE RECOLECCIÓN'
  },
  {
    key: 'weight',
    label: 'PESO'
  },
  {
    key: 'final_destination',
    label: 'DESTINO FINAL'
  },
  {
    key: 'actions',
    label: 'ACCIONES'
  }
]

const TableWrapper = styled(Table)`
  > div {
    min-height: 550px;
  }
`

export default function TraceabilityTable({ traceability, loading }) {
  const user = useSelector((state) => state.auth.user)
  const [selectedTraceability, setSelectedTraceability] = useState(null)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onOpenChange: onOpenChangeUpdate
  } = useDisclosure()

  const handleDelete = (traceability) => {
    setSelectedTraceability(traceability)
    onOpen()
  }

  const handleUpdate = (traceability) => {
    setSelectedTraceability(traceability)
    onOpenUpdate()
  }

  if (!traceability) return null

  return (
    <>
      <TableWrapper>
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={traceability}
          isLoading={loading}
          loadingContent={<Spinner />}
          emptyContent={
            <div className="flex items-center justify-center min-h-[480px]">
              <p className="text-xl">No Hay Trazabilidades Registradas.</p>
            </div>
          }
        >
          {(t) => (
            <TableRow key={t.id}>
              <TableCell>
                {user?.role === Roles.PROVIDER_ADMIN ? t?.organization?.name : t?.provider?.name}
              </TableCell>
              <TableCell>{formatDate(t.created_at, 'DD/MM/YY')}</TableCell>
              <TableCell>{t?.pickup?.detail}</TableCell>
              <TableCell>
                <div className="flex gap-1">
                  {t?.items?.map((i) => (
                    <Tooltip
                      key={i.id}
                      content={`(${i.waste.category_code}) ${i.waste.category_name}`}
                    >
                      <Chip color="primary">
                        {i.weight}
                        {i.unit}
                      </Chip>
                    </Tooltip>
                  ))}
                </div>
              </TableCell>
              <TableCell>
                {t?.items.map((i) => (
                  <div key={i.id} className="flex flex-col gap-1">
                    <Tooltip content={`(${i.waste.category_code}) ${i.waste.category_name}`}>
                      <p>{translate(i.final_destination)}</p>
                    </Tooltip>
                  </div>
                ))}
              </TableCell>
              <TableCell>
                <Tooltip content="Editar">
                  <Button isIconOnly variant="light" onPress={() => handleUpdate(t)}>
                    <IoCreateOutline />
                  </Button>
                </Tooltip>
                {user.role === Roles.ORGANIZATION_ADMIN && (
                  <Tooltip content="Eliminar">
                    <Button
                      isIconOnly
                      variant="light"
                      color="danger"
                      onPress={() => handleDelete(t)}
                    >
                      <IoTrashOutline />
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableWrapper>
      <TraceabilityDeleteModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        traceability={selectedTraceability}
      />
      <CustomModal isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate}>
        <UpdateTraceabilityForm
          traceability={selectedTraceability}
          onOpenChange={onOpenChangeUpdate}
        />
      </CustomModal>
    </>
  )
}
