import { useFormik } from 'formik'
import { OrganizationSchema } from 'src/schemas/Organization'
import { toast } from 'sonner'
import { useDispatch } from 'react-redux'
import S3Manager from 'src/services/S3Manager'
import { editOrganization, fetchUserOrganization } from 'src/store/features/organizationsSlice'
import { useQueryClient } from '@tanstack/react-query'

export default function useUpdateOrganization (userOrganization) {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const s3Manager = new S3Manager()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userOrganization?.name,
      rut_number: userOrganization?.rut_number,
      address: userOrganization?.address,
      phone_number: userOrganization?.phone_number,
      category: new Set([userOrganization?.category]),
      subcategory: new Set([userOrganization?.subcategory]),
      department: new Set([userOrganization?.department]),
      location: new Set([userOrganization?.location]),
      operation_sector: new Set([userOrganization?.operation_sector])
    },
    validationSchema: OrganizationSchema,
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        rut_number: values.rut_number,
        address: values.address,
        phone_number: values.phone_number,
        category: values.category.values().next().value,
        subcategory: values.subcategory.values().next().value,
        operation_sector: values.operation_sector.values().next().value,
        department: values.department.values().next().value,
        location: values.location.values().next().value
      }

      try {
        if (values.logo && values?.logo instanceof File) {
          const path = `logos/${userOrganization.id}/logo.${values.logo.name.split('.').pop()}`
          await s3Manager.uploadFile(values.logo, path)
          data.logo = path
        }

        await dispatch(editOrganization({ id: userOrganization.id, data })).unwrap()
        dispatch(fetchUserOrganization(userOrganization.id))
        toast.success(`Empresa ${data.name} actualizada correctamente`)
        await queryClient.invalidateQueries(['organization', userOrganization.id])
      } catch (error) {
        toast.error(`Error al editar la empresa, ${error.message}`)
      }
    }
  })

  const { values, errors, touched, getFieldProps, setFieldValue, handleSubmit } = formik

  return { values, errors, touched, getFieldProps, setFieldValue, handleSubmit, formik }
}
