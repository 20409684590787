import ProvidersTable from './components/ProvidersTable'
import useGetProviders from './hooks/useGetProviders'

export default function ProvidersPanelPage() {
  const { data: providers, isFetching } = useGetProviders()

  return (
    <div className="flex flex-col gap-4 p-4">
      <ProvidersTable providers={providers} loading={isFetching} />
    </div>
  )
}
