import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/pickups')

export default function useSendTraceabilityReminder () {
  return useMutation({
    mutationFn: ({ providerId: pickupId }) => {
      return client.postWithId(pickupId + '/reminder')
    },
    onError: (error) => {
      toast.error(`Error al enviar notificación, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      toast.success('Notificación enviada exitosamente.')
    }
  })
}
