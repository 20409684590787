import { Button, Checkbox, Input, Select, SelectItem, Textarea } from '@nextui-org/react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import CustomDatePicker from 'src/components/CustomDatePicker'
import WasteCard from 'src/pages/Providers/components/WasteCard'
import useGetOrganizationProviders from 'src/pages/Providers/hooks/useGetOrganizationProviders'
import { PickupSchema } from 'src/schemas/Pickup'
import formatDate from 'src/utils/date'
import useEditPickup from '../hooks/useEditPickup'
import { parseDate, getLocalTimeZone, today } from '@internationalized/date'

export default function EditPickupForm({ onOpenChange, pickup, branchOffices }) {
  const { data: providers } = useGetOrganizationProviders()
  const [selectedProvider, setSelectedProvider] = useState(
    providers?.find((wm) => wm.id === pickup.provider_id)
  )
  const { mutate } = useEditPickup(onOpenChange)

  if (!pickup) return null

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickup_date: parseDate(pickup.pickup_date),
      provider_id: new Set([]).add(pickup.provider_id),
      branch_office_id: new Set([]).add(pickup.branch_office_id),
      certificate_number: pickup.certificate_number,
      vehicle_registration_number: pickup.vehicle_registration_number,
      detail: pickup.detail,
      wastes: pickup.wastes.map((waste) => waste.id)
      // cost: pickup.cost,
    },
    validationSchema: PickupSchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        pickup_date: formatDate(values.pickup_date.toDate(), 'yyyy-MM-DD'),
        provider_id: values.provider_id.values().next().value,
        branch_office_id: values.branch_office_id.values().next().value
      }
      mutate({ id: pickup.id, data })
    }
  })

  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue } = formik

  const handleAddWaste = (wasteId) => {
    if (values.wastes.includes(wasteId)) {
      setFieldValue(
        'wastes',
        values.wastes.filter((id) => id !== wasteId)
      )
    } else {
      setFieldValue('wastes', [...values.wastes, wasteId])
    }
  }

  return (
    <div className="flex flex-col w-full gap-6 p-6 justify-center items-center ">
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
          <CustomDatePicker
            label="Fecha de recolección"
            maxValue={today(getLocalTimeZone())}
            value={values.pickup_date}
            onChange={(value) => setFieldValue('pickup_date', value)}
          />
          <Select
            size="sm"
            label="Sucursal"
            selectedKeys={values.branch_office_id}
            onSelectionChange={(value) => setFieldValue('branch_office_id', value)}
            renderValue={(items) => {
              return items.map((item) => {
                return item.props.children
              })
            }}
          >
            {branchOffices?.map((bo) => (
              <SelectItem key={bo.id} value={bo.id}>
                {bo.address}, {bo.location}, {bo.department}
              </SelectItem>
            ))}
          </Select>
          <Select
            size="sm"
            label="Proveedor"
            selectedKeys={values.provider_id}
            onSelectionChange={(value) => {
              const key = value.values().next().value
              setSelectedProvider(providers.find((wm) => wm.id === key))
              setFieldValue('wastes', [])
              setFieldValue('provider_id', value)
            }}
          >
            {providers?.map((l) => (
              <SelectItem key={l.id} value={l.id}>
                {l.name}
              </SelectItem>
            ))}
          </Select>
          <div className="flex flex-col gap-2">
            <span className="pl-1 text-xs text-gray-400">Residuos Recolectados</span>
            {selectedProvider?.wastes?.map((waste) => (
              <div className="flex gap-2" key={waste.id}>
                <Checkbox
                  isSelected={values.wastes.includes(waste.id)}
                  onValueChange={() => handleAddWaste(waste.id)}
                />
                <WasteCard waste={waste} />
              </div>
            ))}
          </div>
          <Input
            {...getFieldProps('certificate_number')}
            size="sm"
            isInvalid={Boolean(touched.certificate_number && errors.certificate_number)}
            errorMessage={touched.certificate_number && errors.certificate_number}
            label="Número de constancia"
          />
          <Input
            {...getFieldProps('vehicle_registration_number')}
            size="sm"
            isInvalid={Boolean(
              touched.vehicle_registration_number && errors.vehicle_registration_number
            )}
            errorMessage={touched.vehicle_registration_number && errors.vehicle_registration_number}
            label="Matrícula del vehículo"
          />
          <Textarea
            {...getFieldProps('detail')}
            label="Detalle"
            placeholder="Detalle de la recolección"
          />
          {/* <Input
                {...getFieldProps('cost')}
                type="number"
                size="sm"
                isInvalid={Boolean(touched.cost && errors.cost)}
                errorMessage={touched.cost && errors.cost}
                label="Costo de recolección"
              /> */}
          <Button type="submit" color="primary">
            Editar Recolección
          </Button>
        </Form>
      </FormikProvider>
    </div>
  )
}
