import { useFormik } from 'formik'
import { useState } from 'react'
import useNavigation from 'src/pages/OnboardingPage/hooks/useNavigation'
import { TraceabilitySchema } from 'src/schemas/Traceability'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import useEditTraceability from '../hooks/useEditTraceability'

export default function UpdateTraceabilityForm({ traceability, onOpenChange }) {
  const { mutate } = useEditTraceability(onOpenChange)
  const { step, handleNextStep, handlePreviousStep } = useNavigation()

  const [selectedPickup, setSelectedPickup] = useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pickup_id: new Set([]).add(traceability.pickup_id),
      items: traceability.items.map((item) => ({
        waste_id: item.waste_id,
        weight: item.weight,
        unit: item.unit,
        final_destination: item.final_destination
      }))
    },
    validationSchema: TraceabilitySchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        pickup_id: values.pickup_id.values().next().value
      }
      mutate({ id: traceability.id, data })
    }
  })

  return (
    <div className="flex flex-col w-full gap-6">
      <h1 className="text-2xl text-primary">Actualizar Trazabilidad</h1>
      {step === 1 && (
        <StepOne
          selectedPickup={selectedPickup}
          setSelectedPickup={setSelectedPickup}
          formik={formik}
          onNextStep={handleNextStep}
          editMode={true}
        />
      )}
      {step === 2 && (
        <StepTwo
          traceability={traceability}
          selectedPickup={selectedPickup}
          formik={formik}
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
        />
      )}
    </div>
  )
}
