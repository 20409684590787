import { Modal, ModalContent, ModalHeader, ModalBody } from '@nextui-org/react'
import CreateBranchOfficeForm from './CreateBranchOfficeForm'

export default function ModalBranchOffice ({ isOpen, onOpenChange }) {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent className='py-4'>
        <ModalHeader className="flex flex-col gap-1">Nueva Sucursal</ModalHeader>
        <ModalBody>
          <CreateBranchOfficeForm onOpenChange={onOpenChange}/>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
