import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'

export default function VerifyEmailLayout () {
  return (
    <div className="bg-sky-50 flex flex-col items-center justify-center min-h-screen">
      <Outlet />
      <Toaster richColors />
    </div>
  )
}
