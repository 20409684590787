import { Steps } from 'antd'

export default function Navigation ({ step, onPreviousStep, onNextStep }) {
  const items = [
    {
      title: 'Información'
    },
    {
      title: 'Impacto'
    },
    {
      title: 'Residuos'
    }
  ]

  return (
    <Steps
      className="absolute top-0 right-0 left-0 p-6 pt-8 bg-gray-50 rounded-xl -z-10"
      size="small"
      current={step - 1}
      items={items}
    />
  )
}
