import { Card, Image, Tab, Tabs } from '@nextui-org/react'
import Logo from 'src/assets/logo.svg'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import { useState } from 'react'

export default function LoginCard({ email }) {
  const [selected, setSelected] = useState('login')

  return (
    <Card className="flex flex-col bg-white min-h-[650px] w-[450px] gap-4 p-4 sm:p-10 rounded-3xl">
      <Image src={Logo} width={60} radius="none" />
      <div>
        <h1 className="text-4xl text-primary font-semibold tracking-wide max-w-sm">
          {selected === 'login' ? 'Inicia Sesión' : 'Regístrate'}
        </h1>
        <p className="text-zinc-400">
          {selected === 'login'
            ? 'Ingresa los datos de tu cuenta.'
            : 'Completa el formulario con tus datos.'}
        </p>
      </div>
      <Tabs fullWidth size="md" selectedKey={selected} onSelectionChange={setSelected}>
        <Tab key="login" title="Iniciar Sesión" className="w-full">
          <LoginForm setSelected={setSelected} email={email} />
        </Tab>
        <Tab key="sign-up" type="submit" title="Registrarte" className="w-full">
          <SignUpForm setSelected={setSelected} />
        </Tab>
      </Tabs>
    </Card>
  )
}
