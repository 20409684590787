import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner
} from '@nextui-org/react'
import formatDate from 'src/utils/date'

const columns = [
  {
    key: 'name',
    label: 'NOMBRE'
  },
  {
    key: 'address',
    label: 'DIRECCIÓN'
  },
  {
    key: 'rut',
    label: 'RUT'
  },
  {
    key: 'responsible',
    label: 'RESPONSABLE'
  },
  {
    key: 'created_at',
    label: 'FECHA DE REGISTRO'
  }

]

export default function OrganizationsTable ({ organizations, loading }) {
  if (!organizations) return null

  return (
      <Table>
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={organizations}
          isLoading={loading}
          loadingContent={<Spinner />}
          emptyContent={'No rows to display.'}
        >
          {(organization) => (
            <TableRow key={organization.id}>
              <TableCell>{organization.name}</TableCell>
              <TableCell>{organization.address}</TableCell>
              <TableCell>{organization.rut_number}</TableCell>
              <TableCell>{organization.admin.name}</TableCell>
              <TableCell>{formatDate(organization.created_at)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
  )
}
