import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/providers')

export default function useUpdateProvider (onOpenChange, resetStep) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => {
      return client.put(id, data)
    },
    onError: (error) => {
      toast.error(`Error al actualizar gestor, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['providers'])
      toast.success('Gestor actualizado correctamente')
      onOpenChange()
      resetStep()
    }
  })
}
