import { Modal, ModalContent, ModalBody } from '@nextui-org/react'

export default function CustomModal({
  size = '2xl',
  backdrop = 'blur',
  isOpen,
  onOpenChange,
  children
}) {
  return (
    <Modal
      backdrop={backdrop}
      size={size}
      isDismissable={false}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="outside"
      className="p-6 rounded-xl"
    >
      <ModalContent>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
