import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/traceability')

export default function useCreateTraceability (onOpenChange) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => {
      return client.post(data)
    },
    onError: (error) => {
      toast.error(`Error al crear la trazabilidad, ${error.response.data.detail}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['traceability'])
      toast.success('Trazabilidad creada exitosamente.')
      onOpenChange()
    }
  })
}
