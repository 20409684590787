import { Image } from '@nextui-org/react'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'

export default function ProviderCardSmall({ provider }) {
  const { fileUrl } = useGetS3ObjectUrl(provider?.logo)

  return (
    <div className="flex gap-4 w-full items-center bg-white border-1 border-gray-100 p-2 px-3 rounded-xl">
      <Image
        src={fileUrl}
        className="h-12 w-12 rounded-xl border-1 border-gray-100 object-contain"
      />
      <p>{provider?.name}</p>
    </div>
  )
}
