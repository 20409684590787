import { useFormik } from 'formik'
import { toast } from 'sonner'
import S3Manager from 'src/services/S3Manager'
import useCreateProvider from './useCreateProvider'
import { useDispatch, useSelector } from 'react-redux'
import formatDate from 'src/utils/date'
import { ProviderSchema } from 'src/schemas/ProviderSchema'
import { useState } from 'react'
import { Roles } from 'src/constants'
import { assignProvider } from 'src/store/features/authSlice'

export default function useCreateProviderForm (onOpenChange, resetStep) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const [loading, setLoading] = useState(false)
  const wastes = useSelector((state) => state.wastes.wastes)
  const s3Manager = new S3Manager()
  const { mutate } = useCreateProvider(onOpenChange, resetStep)

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ProviderSchema,
    initialValues: {
      logo: '',
      name: '',
      rut_number: '',
      type: new Set([]),
      phone_number: '',
      email: '',
      responsible_name: '',
      department: new Set([]),
      location: new Set([]),
      fleet: new Set([]),
      address: '',
      dgi_certificate: {
        type: 'dgi',
        expiration_date: null,
        file: null
      },
      bps_certificate: {
        type: 'bps',
        expiration_date: null,
        file: null
      },
      insurance_certificate: {
        type: 'insurance',
        expiration_date: null,
        file: null
      },
      vehicle_qualification: {
        department: '',
        enablement_number: '',
        expiration_date: null
      },
      solid_waste_qualification: {
        department: '',
        enablement_number: '',
        expiration_date: null
      },
      iso_certificate: {
        type: 'iso',
        expiration_date: null,
        file: null
      },
      b_company_certificate: {
        type: 'b_company',
        expiration_date: null,
        file: null
      },
      great_place_to_work_certificate: {
        type: 'great_place_to_work',
        expiration_date: null,
        file: null
      },
      sustentability_commitment_certificate: {
        type: 'sustentability_commitment',
        file: null
      },
      woman_in_leadership_positions: null,
      gender_equity_policy: null,
      inclusion_programs: null,
      footprint_reduction_programs: null,
      created_by: user?.id
    },
    onSubmit: async (values) => {
      setLoading(true)
      const id = self.crypto.randomUUID()

      try {
        await s3Manager.uploadFile(
          values.dgi_certificate.file,
          `certifications/${id}/dgi_certificate.${values.dgi_certificate.file.name
            .split('.')
            .pop()}`
        )
        await s3Manager.uploadFile(
          values.insurance_certificate.file,
          `certifications/${id}/insurance_certificate.${values.insurance_certificate.file.name
            .split('.')
            .pop()}`
        )
        await s3Manager.uploadFile(
          values.bps_certificate.file,
          `certifications/${id}/bps_certificate.${values.bps_certificate.file.name
            .split('.')
            .pop()}`
        )

        if (values.iso_certificate.file) {
          await s3Manager.uploadFile(
            values.iso_certificate.file,
            `certifications/${id}/iso_certificate.${values.iso_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values.b_company_certificate.file) {
          await s3Manager.uploadFile(
            values.b_company_certificate.file,
            `certifications/${id}/b_company_certificate.${values.b_company_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values.great_place_to_work_certificate.file) {
          await s3Manager.uploadFile(
            values.great_place_to_work_certificate.file,
            `certifications/${id}/great_place_to_work_certificate.${values.great_place_to_work_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        if (values.sustentability_commitment_certificate.file) {
          await s3Manager.uploadFile(
            values.sustentability_commitment_certificate.file,
            `certifications/${id}/sustentability_commitment_certificate.${values.sustentability_commitment_certificate.file.name
              .split('.')
              .pop()}`
          )
        }
        toast.success('Certificados subidos correctamente')
      } catch (error) {
        toast.error('Ha ocurrido un error al subir los certificados')
      }

      const data = {
        id,
        name: values.name,
        rut_number: values.rut_number,
        type: values.type.values().next().value,
        phone_number: values.phone_number,
        email: values.email,
        responsible_name: values.responsible_name,
        address: values.address,
        department: values.department.values().next().value,
        location: values.location.values().next().value,
        fleet: values.fleet.values().next().value,
        created_by: values.created_by,
        dgi_certificate: {
          type: values.dgi_certificate.type,
          expiration_date: formatDate(
            values.dgi_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/dgi_certificate.${values.dgi_certificate.file.name
            .split('.')
            .pop()}`
        },
        bps_certificate: {
          type: values.bps_certificate.type,
          expiration_date: formatDate(
            values.bps_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/bps_certificate.${values.bps_certificate.file.name
            .split('.')
            .pop()}`
        },
        insurance_certificate: {
          type: values.insurance_certificate.type,
          expiration_date: formatDate(
            values.insurance_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/insurance_certificate.${values.insurance_certificate.file.name
            .split('.')
            .pop()}`
        },
        wastes
      }

      try {
        if (values.logo) {
          const path = `logos/${id}/logo.${values.logo.name.split('.').pop()}`
          await s3Manager.uploadFile(values.logo, path)
          data.logo = path
        }
      } catch (error) {
        toast.error('Ha ocurrido un error al subir el logo')
        return
      }

      // Optional fields
      if (values.vehicle_qualification.expiration_date) {
        data.vehicle_qualification = {
          type: 'vehicle',
          department: values.vehicle_qualification.department.values().next().value,
          enablement_number: values.vehicle_qualification.enablement_number,
          expiration_date: formatDate(
            values.vehicle_qualification.expiration_date.toDate(),
            'yyyy-MM-DD'
          )
        }
      }
      if (values.solid_waste_qualification.expiration_date) {
        data.solid_waste_qualification = {
          type: 'solid_waste',
          department: values.solid_waste_qualification.department.values().next().value,
          enablement_number: values.solid_waste_qualification.enablement_number,
          expiration_date: formatDate(
            values.solid_waste_qualification.expiration_date.toDate(),
            'yyyy-MM-DD'
          )
        }
      }

      if (values.iso_certificate.file) {
        data.iso_certificate = {
          type: values.iso_certificate.type,
          expiration_date: formatDate(
            values.iso_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/iso_certificate.${values.iso_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values.b_company_certificate.file) {
        data.b_company_certificate = {
          type: values.b_company_certificate.type,
          expiration_date: formatDate(
            values.b_company_certificate.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/b_company_certificate.${values.b_company_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values.great_place_to_work_certificate.file) {
        data.great_place_to_work_certificate = {
          type: values.great_place_to_work_certificate.type,
          expiration_date: formatDate(
            values?.great_place_to_work_certificate?.expiration_date.toDate(),
            'yyyy-MM-DD'
          ),
          file_path: `certifications/${id}/great_place_to_work_certificate.${values.great_place_to_work_certificate.file.name
            .split('.')
            .pop()}`
        }
      }
      if (values.woman_in_leadership_positions) {
        data.woman_in_leadership_positions =
          values.woman_in_leadership_positions
      }
      if (values.gender_equity_policy) {
        data.gender_equity_policy = values.gender_equity_policy
      }
      if (values.inclusion_programs) {
        data.inclusion_programs = values.inclusion_programs
      }
      if (values.footprint_reduction_programs) {
        data.footprint_reduction_programs = values.footprint_reduction_programs
      }

      if (values.sustentability_commitment_certificate.file) {
        data.sustentability_commitment_certificate = {
          type: values.sustentability_commitment_certificate.type,
          file_path: `certifications/${id}/sustentability_commitment_certificate.${values.sustentability_commitment_certificate.file.name
            .split('.')
            .pop()}`
        }
      }

      mutate({ data })
      setLoading(false)

      if (user?.role === Roles.PROVIDER_ADMIN) {
        console.log('assigning provider')
        dispatch(assignProvider(id))
      }
    }
  })

  const { values } = formik

  const isFirstStepValid = () =>
    values.name &&
    values.rut_number &&
    values.phone_number &&
    values.email &&
    values.responsible_name &&
    values.address &&
    values.department.size > 0 &&
    values.location.size > 0 &&
    values.bps_certificate.file &&
    values.bps_certificate.expiration_date &&
    values.dgi_certificate.file &&
    values.dgi_certificate.expiration_date &&
    values.insurance_certificate.file &&
    values.insurance_certificate.expiration_date

  const isSecondStepValid = () => true

  return { formik, loading, isFirstStepValid, isSecondStepValid }
}
