import { Button, Divider } from '@nextui-org/react'
import { RiEditLine } from 'react-icons/ri'
import useGetS3ObjectUrl from 'src/hooks/useGetS3ObjectUrl'
import capitalize from 'src/utils/capitalize'
import WasteCard from '../WasteCard'
import CertificateCard from './CertificateCard'
import { useSelector } from 'react-redux'

export default function ProviderDetail({ provider, onEdit, editable = true }) {
  const user = useSelector((state) => state.auth.user)
  if (!provider) return null

  const { fileUrl } = useGetS3ObjectUrl(provider?.logo)

  return (
    <>
      <div className="flex flex-col gap-4 p-2">
        <div>
          <div className="flex items-center justify-between pr-2">
            <div className="flex gap-4 items-center">
              <div className="flex items-center justify-center mb-3">
                <img
                  src={fileUrl}
                  className="h-20 w-20 rounded-xl border-1 border-gray-100 object-contain"
                />
              </div>
              <h2 className="text-3xl font-bold">{provider?.name}</h2>
            </div>
            {editable && user.id === provider?.created_by && (
              <Button
                endContent={<RiEditLine />}
                variant="bordered"
                color="primary"
                onPress={() => onEdit(provider)}
              >
                Modificar
              </Button>
            )}
          </div>
          <Divider className="my-2" />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <p className="text-xs font-bold tracking-wide uppercase text-primary">
              Perfil de la Empresa
            </p>
            <span className="bg-gray-50 rounded-md p-2">{provider?.name}</span>
            <span className="bg-gray-50 rounded-md p-2">{provider?.rut_number}</span>
            <span className="bg-gray-50 rounded-md p-2">{provider?.phone_number}</span>
            <span className="bg-gray-50 rounded-md p-2">{provider?.email}</span>
            <span className="bg-gray-50 rounded-md p-2">{provider?.responsible_name}</span>
            <span className="bg-gray-50 rounded-md p-2">
              {capitalize(provider?.department)}, {capitalize(provider?.location)}
            </span>
            <span className="bg-gray-50 rounded-md p-2">{provider?.address}</span>
          </div>
          <div className="">
            <p className="text-xs font-bold tracking-wide uppercase text-primary">
              Certificados y Habilitaciónes
            </p>
            {provider.certifications?.map((c) => (
              <CertificateCard key={c.id} c={c} />
            ))}
            {provider.qualifications?.map((q) => (
              <CertificateCard key={q.id} c={q} />
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full gap-3 justify-center mt-4">
        <h1 className="text-xs font-bold tracking-wide uppercase text-primary">
          Residuos que gestionan
        </h1>
        {provider?.wastes?.length === 0 && (
          <p className="text-gray-400">No hay residuos gestionados.</p>
        )}
        {provider?.wastes?.map((waste, index) => (
          <WasteCard key={index} waste={waste} />
        ))}
      </div>
    </>
  )
}
