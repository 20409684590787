import axios from 'axios'
import { useEffect, useState } from 'react'

export default function useGetLocations (department) {
  const [locations, setLocations] = useState([])
  const uri = 'https://direcciones.ide.uy/api/v0/geocode/localidades'

  useEffect(() => {
    if (department) {
      axios.get(uri, {
        params: {
          alias: false,
          departamento: department
        }
      })
        .then((response) => {
          const locations = response.data.map((location) => ({
            id: location.id,
            name: location.nombre.toLowerCase(),
            code: location.codigoPostal
          }))
          locations.push({ id: 0, name: 'otro', code: '0000' })
          setLocations(locations)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
  , [department])

  return { locations }
}
