import { Form, FormikProvider, useFormik } from 'formik'
import { Button, Input } from '@nextui-org/react'
import useChangePassword from '../hooks/useChangePassword'

export default function ChangePasswordForm() {
  const { mutate, isPending } = useChangePassword()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: ''
    },
    onSubmit: async (values, { resetForm }) => {
      await mutate({ data: { password: values.password } })
      resetForm()
    }
  })

  const { handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col gap-4 max-w-md" onSubmit={handleSubmit}>
        <Input {...getFieldProps('password')} size="sm" label="Contraseña" />
        <Button size="sm" type="submit" color="primary" className="w-fit" isLoading={isPending}>
          Actualizar
        </Button>
      </Form>
    </FormikProvider>
  )
}
