export default function InfoBox ({ title, value, divider = true }) {
  return (
    <div className="flex gap-6 items-center text-gray-500">
      <div className="p-3 px-4 rounded-xl border-1 border-gray-200">
        <span>{value}</span>
      </div>
      <span className="uppercase text-sm tracking-wider">{title}</span>
      {divider && <div className="h-full w-[1px] bg-gray-200 ml-2" />}
    </div>
  )
}
