import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import { useState } from 'react'

export default function CustomFileUpload({ onChange }) {
  const [fileList, setFileList] = useState([])

  const handleChange = ({ fileList: newFileList }) => {
    setFileList([...newFileList])
    onChange(newFileList[0]?.originFileObj)
  }

  return (
    <Upload
      fileList={fileList}
      onChange={handleChange}
      className="w-[180px]"
      accept=".pdf,.png,.jpeg,.jpg"
      beforeUpload={() => false}
    >
      {fileList.length === 0 && <Button icon={<UploadOutlined />}>Subir Archivo</Button>}
    </Upload>
  )
}
