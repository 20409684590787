import { Button, Image } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { decodeToken } from 'src/utils/jwt'
import { verifyEmail } from '../store/features/authSlice'
import LogoWithText from 'src/assets/logo-with-text.svg'
import { Routes } from 'src/routes'

export default function EmailVerification() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [mailAddress, setMailAddress] = useState()

  const handleVerifyEmail = async (token, email) => {
    try {
      await dispatch(verifyEmail({ token })).unwrap()
      toast.success(`Email ${email} verificado correctamente`)
    } catch (err) {
      toast.error(`Error al verificar email ${email}`)
    }
  }

  const handleNavigate = () => {
    navigate(Routes.LOGIN, { state: { email: mailAddress } })
  }

  useEffect(() => {
    const token = searchParams.get('token')
    const decodedToken = decodeToken(token)
    setMailAddress(decodedToken.email)
    handleVerifyEmail(token, decodedToken.email)
  }, [])

  return (
    <div className="flex flex-row p-6 gap-10 min-h-[80vh] items-center justify-center">
      <div className="flex flex-col gap-3 ">
        <h1 className="text-6xl text-primary font-extrabold max-w-md">Verificación de email</h1>
        <h4 className="text-xl font-bold max-w-md mb-10">
          La dirección de email {mailAddress} ha sido verificada, para finalizar el registro ingresa
          a tu cuenta.
        </h4>
        <Button onPress={handleNavigate} color="primary" className="w-fit">
          Ingresar a tu cuenta
        </Button>
      </div>
      <Image src={LogoWithText} width={200} />
    </div>
  )
}
