import { Form, FormikProvider } from 'formik'
import { Button, Input, Select, SelectItem } from '@nextui-org/react'
import { operationSectors } from 'src/constants'
import departments from 'src/data/departments.json'
import useGetLocations from 'src/hooks/useGetLocations'
import useGetCategories from 'src/hooks/useGetCategories'
import useCreateOrganization from '../hooks/useCreateOrganization'
import ImagePreviewer from 'src/components/ImagePreview'
import Navigation from './Navigation'

export default function CreateOrganizationForm ({ onNextStep, user }) {
  const { formik } = useCreateOrganization(onNextStep)
  const { handleSubmit, values, errors, touched, getFieldProps, setFieldValue } = formik
  const { locations } = useGetLocations(values.department?.values().next().value)
  const { categories, subcategories } = useGetCategories(values.category?.values().next().value)

  return (
    <FormikProvider value={formik}>
      <div className="flex items-center justify-center mb-3">
        <ImagePreviewer onChange={(file) => setFieldValue('logo', file)} />
      </div>
      <Form className="flex flex-col gap-3" onSubmit={handleSubmit}>
        <Input value={user?.name} size="sm" isDisabled={true} label="Responsable" />
        <Input
          {...getFieldProps('name')}
          size="sm"
          isInvalid={Boolean(touched.name && errors.name)}
          errorMessage={touched.name && errors.name}
          label="Nombre de la Empresa"
        />
        <Input
          {...getFieldProps('rut_number')}
          size="sm"
          isInvalid={Boolean(touched.rut_number && errors.rut_number)}
          errorMessage={touched.rut_number && errors.rut_number}
          label="Número de RUT"
        />
        <Select
          size="sm"
          label="Clasificación de Empresa"
          selectedKeys={values.category}
          onSelectionChange={(value) => setFieldValue('category', value)}
          renderValue={(items) => {
            return items.map((item) => {
              return item.props.children
            })
          }}
        >
          {categories?.map((c) => (
            <SelectItem key={c.name} value={c.name}>
              {c.code} - {c.name}
            </SelectItem>
          ))}
        </Select>
        <Select
          isDisabled={values.category === ''}
          size="sm"
          label="Subclasificación"
          selectedKeys={values.subcategory}
          onSelectionChange={(value) => setFieldValue('subcategory', value)}
          renderValue={(items) => {
            return items.map((item) => {
              return item.props.children
            })
          }}
        >
          {subcategories?.map((sc) => (
            <SelectItem key={sc.name} value={sc.name}>
              {`${sc.code} - ${sc.name}`}
            </SelectItem>
          ))}
        </Select>
        <Select
          size="sm"
          label="Areas Funcionales"
          selectedKeys={values.operation_sector}
          onSelectionChange={(value) => setFieldValue('operation_sector', value)}
        >
          {operationSectors.map((l) => (
            <SelectItem className=" whitespace-normal" key={l.value} value={l.value}>
              {l.label}
            </SelectItem>
          ))}
        </Select>

        <Input
          {...getFieldProps('phone_number')}
          size="sm"
          isInvalid={Boolean(touched.phone_number && errors.phone_number)}
          errorMessage={touched.phone_number && errors.phone_number}
          label="Teléfono de Contacto"
        />
        <Select size="sm" label="Departamento" selectedKeys={values.department} onSelectionChange={(value) => setFieldValue('department', value)}>
          {departments?.map((l) => (
            <SelectItem key={l.value} value={l.value}>
              {l.label}
            </SelectItem>
          ))}
        </Select>
        <Select size="sm" label="Localidad" selectedKeys={values.location} onSelectionChange={(value) => setFieldValue('location', value)}>
          {locations?.map((l) => (
            <SelectItem key={l.name} value={l.name}>
              {`${l.name} (${l.code})`}
            </SelectItem>
          ))}
        </Select>
        <Input
          {...getFieldProps('address')}
          size="sm"
          isInvalid={Boolean(touched.address && errors.address)}
          errorMessage={touched.address && errors.address}
          label="Dirección"
        />
        <Navigation step={2} onNextStep={handleSubmit}>
          <Button type="submit" variant="bordered" color="primary" className="px-10 mt-2" isDisabled={!formik.isValid}>
            Continuar
          </Button>
        </Navigation>
      </Form>
    </FormikProvider>
  )
}
