import { object, string, ref } from 'yup'

const UserSchema = object().shape({
  email: string().email('Email inválido').required('Email requerido'),
  name: string().required('Nombre requerido').min(4, 'El nombre debe tener al menos 4 caracteres'),
  password: string()
    .required('Contraseña requerida')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
    .matches(/[a-z]/, 'La contraseña debe tener al menos una letra minúscula')
    .matches(/[A-Z]/, 'La contraseña debe tener al menos una letra mayúscula'),
  passwordConfirmation: string().required('Contraseña requerida')
    .oneOf([ref('password')], 'Las contraseñas no coinciden')
})

export default UserSchema
