import { Select, SelectItem } from '@nextui-org/react'
import { Form, FormikProvider } from 'formik'
import { useSelector } from 'react-redux'
import formatDate from 'src/utils/date'
import Navigation from './Navigation'
import PickupDetailCard from './PickupDetailCard'
import useGetPickupsByOrganization from 'src/hooks/useGetPickupsByOrganization'
import useGetPickupsByProvider from 'src/hooks/useGetPickupsByProvider'
import { Roles } from 'src/constants'

export default function StepOne({
  selectedPickup,
  setSelectedPickup,
  formik,
  onNextStep,
  editMode = false
}) {
  const user = useSelector((state) => state.auth.user)
  const { data } =
    user?.role === Roles.ORGANIZATION_ADMIN
      ? useGetPickupsByOrganization({ traceability: editMode ? undefined : false })
      : useGetPickupsByProvider({ traceability: false })

  const { values, setFieldValue } = formik

  const pickups = data?.items || []

  return (
    <div className="w-full">
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-4  min-h-[400px]">
          <Select
            size="sm"
            label="Recolecciones"
            selectedKeys={values.pickup_id}
            onSelectionChange={(value) => {
              const key = value.values().next().value
              setFieldValue('pickup_id', value)
              setSelectedPickup(pickups.find((p) => p.id === key))
            }}
            renderValue={(items) => items.map((item) => item.props.children)}
          >
            {pickups?.map((p) => (
              <SelectItem key={p.id} value={p.id}>
                {p.detail} / {formatDate(p.created_at)}
              </SelectItem>
            ))}
          </Select>
          <PickupDetailCard pickup={selectedPickup} />
        </Form>
        <Navigation
          step={1}
          onNextStep={onNextStep}
          isNextStepDisabled={formik.pickup_id === null}
        />
      </FormikProvider>
    </div>
  )
}
