import { useFormik } from 'formik'
import { OrganizationSchema } from 'src/schemas/Organization'
import { createOrganization, createBranchOffice } from 'src/store/features/organizationsSlice'
import { toast } from 'sonner'
import { useDispatch, useSelector } from 'react-redux'
import S3Manager from 'src/services/S3Manager'

export default function useCreateOrganization (onNextStep) {
  const user = useSelector((s) => s.auth.user)
  const dispatch = useDispatch()
  const s3Manager = new S3Manager()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: '',
      name: '',
      rut_number: '',
      operation_sector: new Set([]),
      category: new Set([]),
      subcategory: new Set([]),
      department: new Set([]),
      location: new Set([]),
      address: '',
      phone_number: ''
    },
    validationSchema: OrganizationSchema,
    onSubmit: async (values) => {
      const id = self.crypto.randomUUID()
      const data = {
        id,
        name: values.name,
        rut_number: values.rut_number,
        address: values.address,
        phone_number: values.phone_number,
        category: values.category.values().next().value,
        subcategory: values.subcategory.values().next().value.split('.')[0],
        operation_sector: values.operation_sector.values().next().value,
        department: values.department.values().next().value,
        location: values.location.values().next().value
      }
      try {
        if (values.logo) {
          const path = `logos/${id}/logo.${values.logo.name.split('.').pop()}`
          await s3Manager.uploadFile(values.logo, path)
          data.logo = path
        }

        const branchOffice = {
          organization_id: id,
          department: values.department.values().next().value,
          location: values.location.values().next().value,
          address: values.address,
          responsible_name: user.name,
          phone_number: values.phone_number,
          email: user.email
        }

        await dispatch(createOrganization(data)).unwrap()
        await dispatch(createBranchOffice(branchOffice)).unwrap()
        toast.success(`Empresa ${data.name} creada correctamente`)
        onNextStep()
      } catch (error) {
        toast.error(`Error al crear la empresa, ${error.message}`)
      }
    }
  })

  return { formik }
}
