import { useState } from 'react'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
  Button,
  useDisclosure,
  Spinner
} from '@nextui-org/react'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import ActivateUserModal from './ActivateUserModal'
import formatDate from 'src/utils/date'
import translate from 'src/utils/translate'
import { Roles } from 'src/constants'

const columns = [
  {
    key: 'name',
    label: 'NOMBRE'
  },
  {
    key: 'email',
    label: 'EMAIL'
  },
  {
    key: 'organization',
    label: 'Empresa'
  },
  {
    key: 'role',
    label: 'ROL'
  },
  {
    key: 'status',
    label: 'ESTADO'
  },
  {
    key: 'created_at',
    label: 'FECHA DE REGISTRO'
  },
  {
    key: 'actions',
    label: 'ACCIONES'
  }
]

const getRoleColor = (role) => {
  switch (role) {
    case 'admin':
      return 'success'
    case 'organization_admin':
      return 'primary'
    case 'provider_admin':
      return 'warning'
    default:
      return 'default'
  }
}

export default function UsersTable({ users, loading }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [selectedUser, setSelectedUser] = useState(null)

  const handleActivateModal = (user) => {
    setSelectedUser(user)
    onOpen()
  }

  if (!users) return null

  console.log(users)

  return (
    <>
      <Table>
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={users}
          isLoading={loading}
          loadingContent={<Spinner />}
          emptyContent={'No rows to display.'}
        >
          {(user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.role === Roles.ORGANIZATION_ADMIN
                  ? user.organization?.name
                  : user.provider?.name}
              </TableCell>
              <TableCell>
                <Chip className="capitalize" color={getRoleColor(user?.role)} variant="flat">
                  {translate(user.role)}
                </Chip>
              </TableCell>
              <TableCell>
                <Chip
                  className="capitalize"
                  color={user.disabled ? 'danger' : 'success'}
                  variant="flat"
                >
                  {user.disabled ? 'Deshabilitado' : 'Activo'}
                </Chip>
              </TableCell>
              <TableCell>{formatDate(user.created_at, 'LL')}</TableCell>
              <TableCell>
                <div className="relative flex items-center gap-2">
                  {
                    <Tooltip content="Activar Cuenta">
                      <Button
                        isDisabled={!user.disabled}
                        isIconOnly
                        color={user.disabled ? 'default' : 'success'}
                        variant="light"
                        onPress={() => handleActivateModal(user)}
                      >
                        <IoIosCheckmarkCircle size={22} />
                      </Button>
                    </Tooltip>
                  }
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ActivateUserModal isOpen={isOpen} onOpenChange={onOpenChange} selectedUser={selectedUser} />
    </>
  )
}
