import { Form, FormikProvider } from 'formik'
import { Checkbox, Input, Select, SelectItem } from '@nextui-org/react'
import { useState } from 'react'
import { fleetTypes } from 'src/constants'
import FileField from 'src/components/FileField'

export default function CertificationForm ({ formik }) {
  const { setFieldValue, getFieldProps, values } = formik
  const [isoCertification, setIsoCertification] = useState(values.iso_certificate?.file_path)
  const [companyBCertification, setCompanyBCertification] = useState(
    values.b_company_certificate?.file_path
  )
  const [greatPlaceToWorkCertification, setGreatPlaceToWorkCertification] =
    useState(values.great_place_to_work_certificate?.file_path)
  const [genderPolicies, setGenderPolicies] = useState(
    values.gender_equity_policy
  )
  const [womenLeadershipPositions, setWomenLeadershipPositions] = useState(
    values.woman_in_leadership_positions
  )
  const [inclusionPrograms, setInclusionPrograms] = useState(
    values.inclusion_programs
  )
  const [footprintReductionPrograms, setFootprintReductionPrograms] = useState(
    values.footprint_reduction_programs
  )
  const [sustentabilityCommitment, setSustentabilityCommitment] = useState(
    values.sustentability_commitment_certificate?.file_path
  )
  const [fleet, setFleet] = useState(values.fleet?.size > 1)

  return (
    <div className="flex flex-col w-full gap-6 p-6 justify-center items-center ">
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-4 w-full">
          <span >
            Para medir el impacto de tu proveedor, solicitale que responda a
            las siguientes preguntas:
          </span>
          <div className="flex flex-col gap-4">
            <Checkbox
              isSelected={isoCertification}
              onValueChange={setIsoCertification}
              size="sm"
            >
              ¿Cuenta con certificación ISO?
            </Checkbox>
            {isoCertification && (
              <FileField
                fieldName="iso_certificate"
                formikField={formik.values.iso_certificate}
                setFieldValue={setFieldValue}
              />
            )}

            <Checkbox
              isSelected={companyBCertification}
              onValueChange={setCompanyBCertification}
              size="sm"
            >
              ¿Está certificado como Empresa B?
            </Checkbox>
            {companyBCertification && (
              <FileField
                fieldName="b_company_certificate"
                formikField={formik.values.b_company_certificate}
                setFieldValue={setFieldValue}
              />
            )}

            <Checkbox
              isSelected={greatPlaceToWorkCertification}
              onValueChange={setGreatPlaceToWorkCertification}
              size="sm"
            >
              ¿Great Place to Work?
            </Checkbox>
            {greatPlaceToWorkCertification && (
              <FileField
                fieldName="great_place_to_work_certificate"
                formikField={formik.values.great_place_to_work_certificate}
                setFieldValue={setFieldValue}
              />
            )}

            <Checkbox
              isSelected={genderPolicies}
              onValueChange={setGenderPolicies}
              size="sm"
            >
              ¿Cuenta la empresa con políticas de igualdad de género?
            </Checkbox>
            {genderPolicies && (
              <Input
                {...getFieldProps('gender_equity_policy')}
                label="Explique"
                size="sm"
              />
            )}

            <Checkbox
              isSelected={womenLeadershipPositions}
              onValueChange={setWomenLeadershipPositions}
              size="sm"
            >
              ¿La empresa está liderada por mujeres?
            </Checkbox>
            {womenLeadershipPositions && (
              <Input
                {...getFieldProps('woman_in_leadership_positions')}
                label="Explique"
                size="sm"
              />
            )}

            <Checkbox
              isSelected={inclusionPrograms}
              onValueChange={setInclusionPrograms}
              size="sm"
            >
              ¿Cuenta la empresa con políticas y/o programas de diversidad e
              inclusión?
            </Checkbox>
            {inclusionPrograms && (
              <Input
                {...getFieldProps('inclusion_programs')}
                label="Explique"
                size="sm"
              />
            )}

            <Checkbox isSelected={fleet} onValueChange={setFleet} size="sm">
              ¿Cuenta con vehículo eléctrico?
            </Checkbox>
            {fleet && (
              <Select
                className="mb-2"
                size="sm"
                label="Flota"
                selectedKeys={values.fleet}
                onSelectionChange={(value) => setFieldValue('fleet', value)}
              >
                {fleetTypes.map((l) => (
                  <SelectItem key={l.value} value={l.value}>
                    {l.label}
                  </SelectItem>
                ))}
              </Select>
            )}

            <Checkbox
              isSelected={footprintReductionPrograms}
              onValueChange={setFootprintReductionPrograms}
              size="sm"
            >
              ¿Muestra compromiso para reducir las emisiones de gases de efecto
              invernadero, mejorar la eficiencia energética y del agua, y
              reducir los residuos mediante el apoyo a la reutilización y el
              reciclaje?
            </Checkbox>
            {footprintReductionPrograms && (
              <Input
                {...getFieldProps('footprint_reduction_programs')}
                label="Explique"
                size="sm"
              />
            )}

            <Checkbox
              isSelected={sustentabilityCommitment}
              onValueChange={setSustentabilityCommitment}
              size="sm"
            >
              ¿Es capaz de proporcionar documentación o indicadores para
              sustentar este compromiso sustentable?
            </Checkbox>
            {sustentabilityCommitment && (
              <FileField
                fieldName="sustentability_commitment_certificate"
                formikField={
                  formik.values.sustentability_commitment_certificate
                }
                setFieldValue={setFieldValue}
                expiration={false}
              />
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}
