import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner
} from '@nextui-org/react'
import formatDate from 'src/utils/date'

const columns = [
  {
    key: 'name',
    label: 'NOMBRE'
  },
  {
    key: 'address',
    label: 'DIRECCIÓN'
  },
  {
    key: 'rut',
    label: 'RUT'
  },
  {
    key: 'email',
    label: 'EMAIL DE CONTACTO'
  },
  {
    key: 'responsible_name',
    label: 'RESPONSABLE'
  },
  {
    key: 'created_at',
    label: 'FECHA DE REGISTRO'
  }
]

export default function ProvidersTable({ providers, loading }) {
  if (!providers) return null

  return (
    <Table>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        items={providers}
        isLoading={loading}
        loadingContent={<Spinner />}
        emptyContent={'No hay proovedores registrados.'}
      >
        {(provider) => (
          <TableRow key={provider.id}>
            <TableCell>{provider.name}</TableCell>
            <TableCell>{provider.address}</TableCell>
            <TableCell>{provider.rut_number}</TableCell>
            <TableCell>{provider.email}</TableCell>
            <TableCell>{provider.responsible_name}</TableCell>
            <TableCell>{formatDate(provider.created_at)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
