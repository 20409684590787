import { Button, Tooltip, useDisclosure } from '@nextui-org/react'
import ProviderCreateModal from './components/form/ProviderCreateModal'
import ProviderTable from './components/providers/ProviderTable'
import { AiOutlinePlus } from 'react-icons/ai'
import { useState } from 'react'
import ProviderDetail from './components/providers/ProviderDetail'
import ProviderDeleteModal from './components/providers/ProviderDeleteModal'
import ProviderEditModal from './components/form/ProviderEditModal'
import ProviderPreviewModal from './components/form/ProviderPreviewModal'
import CustomModal from 'src/components/CustomModal'

export default function ProvidersPage() {
  const [selectedProvider, setSelectedProvider] = useState(null)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onOpenChange: onOpenChangeCreate
  } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onOpenChange: onOpenChangeDelete
  } = useDisclosure()
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onOpenChange: onOpenChangeEdit } = useDisclosure()
  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onOpenChange: onOpenChangePreview
  } = useDisclosure()

  const handleOpen = (provider) => {
    setSelectedProvider(provider)
    onOpen()
  }

  const handleOpenDelete = (provider) => {
    setSelectedProvider(provider)
    onOpenDelete()
  }

  const handleOpenEdit = (provider) => {
    setSelectedProvider(provider)
    onOpenEdit()
  }

  const handleEdit = (provider) => {
    onOpenChange()
    setSelectedProvider(provider)
    onOpenEdit()
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <Tooltip content="Agregar un gestor">
        <Button
          isIconOnly
          startContent={<AiOutlinePlus size={20} />}
          color="primary"
          variant="solid"
          onPress={onOpenPreview}
          className="self-end"
        />
      </Tooltip>

      <ProviderTable
        handleOpen={handleOpen}
        handleOpenDelete={handleOpenDelete}
        handleEdit={handleOpenEdit}
      />
      <CustomModal isOpen={isOpenPreview} onOpenChange={onOpenChangePreview}>
        <ProviderPreviewModal onOpenChange={onOpenChangePreview} onOpenCreate={onOpenCreate} />
      </CustomModal>

      <CustomModal isOpen={isOpenCreate} onOpenChange={onOpenChangeCreate}>
        <ProviderCreateModal onOpenChange={onOpenChangeCreate} />
      </CustomModal>

      <CustomModal isOpen={isOpenEdit} onOpenChange={onOpenChangeEdit}>
        <ProviderEditModal provider={selectedProvider} onOpenChange={onOpenChangeEdit} />
      </CustomModal>

      <CustomModal size="3xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ProviderDetail provider={selectedProvider} onEdit={handleEdit} />
      </CustomModal>

      <ProviderDeleteModal
        isOpen={isOpenDelete}
        onOpenChange={onOpenChangeDelete}
        provider={selectedProvider}
      />
    </div>
  )
}
