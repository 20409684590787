import { Button, useDisclosure } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoIosBusiness, IoMdTrash } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import departments from 'src/data/departments.json'
import {
  deleteBranchOffice,
  fetchOrganizationBranchOffices
} from 'src/store/features/organizationsSlice'
import ModalBranchOffice from './ModalAddBranchOffice'
import Navigation from './Navigation'

export default function OnboardingBranchOffices({ onNextStep }) {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const user = useSelector((s) => s.auth.user)
  const { branchOffices } = useSelector((s) => s.organizations)

  useEffect(() => {
    dispatch(fetchOrganizationBranchOffices(user.organization_id))
  }, [])

  const handleDeleteBranchOffice = async (id) => {
    try {
      await dispatch(deleteBranchOffice(id)).unwrap()
      dispatch(fetchOrganizationBranchOffices(user.organization_id))
      toast.success('Sucursal eliminada correctamente')
    } catch (error) {
      toast.error(`Error al eliminar la sucursal, ${error.error}`)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.1,
        ease: 'easeInOut'
      }}
      className="flex flex-col w-full gap-4 min-h-full relative"
    >
      <div className="flex flex-col gap-1 mb-4 items-center">
        <h1 className="text-xl text-center text-primary">Sucursales disponibles</h1>
        {/* <p className="text-sm text-gray-400 text-center max-w-sm">
          Carga las sucursales disponibles en tu empresa.
        </p> */}
      </div>
      {branchOffices?.length === 0 && (
        <span className="bg-gray-50 p-4 rounded-md text-xl text-gray-400 text-center">
          No hay sucursales cargadas
        </span>
      )}
      <div className="flex flex-col gap-2">
        {branchOffices?.map((b) => (
          <div key={b.id} className="flex items-center justify-between bg-gray-50 p-3 rounded-md">
            <div className="flex gap-4 items-center">
              <IoIosBusiness size={30} />
              <div className="flex flex-col">
                <p>{b.address}</p>
                <p className="text-sm text-gray-500">
                  {departments.find((d) => d.value === b.department)?.label}
                </p>
              </div>
            </div>
            <Button
              isIconOnly
              color="danger"
              variant="light"
              size="sm"
              onClick={() => handleDeleteBranchOffice(b.id)}
            >
              <IoMdTrash size={20} />
            </Button>
          </div>
        ))}
      </div>

      <ModalBranchOffice isOpen={isOpen} onOpenChange={onOpenChange} />

      <Button
        onPress={onOpen}
        color="primary"
        variant="light"
        startContent={<AiOutlinePlus size={20} />}
      >
        Agregar Sucursal
      </Button>

      <div className="absolute bottom-0 right-0 left-0">
        <Navigation step={3}>
          <Button variant="bordered" color="primary" className="px-10 mt-2" onClick={onNextStep}>
            Continuar
          </Button>
        </Navigation>
      </div>
    </motion.div>
  )
}
