import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/providers')

export default function useGetProvider (id) {
  return useQuery({
    queryKey: ['provider', id],
    queryFn: () => client.get(id)
  })
}
