import { useState } from 'react'
import { Button, Input, Link } from '@nextui-org/react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from 'src/store/features/authSlice'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'src/routes'
import useForgotPassword from 'src/pages/OnboardingPage/hooks/useForgotPassword'

export default function LoginForm({ setSelected, email }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutate, isPending } = useForgotPassword()

  const loading = useSelector((s) => s.auth.loading)
  const [username, setUsername] = useState(email)
  const [password, setPassword] = useState('')

  const isFormFilled = username && password

  const handleLogin = async () => {
    try {
      await dispatch(loginUser({ username, password })).unwrap()
      navigate(Routes.HOME)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleForgotPassword = async () => {
    try {
      await mutate({ data: { email: username } })
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <form className="flex flex-col gap-4 w-full">
      <Input value={username} onValueChange={setUsername} label="Email" type="text" />
      <Input value={password} onValueChange={setPassword} label="Contraseña" type="password" />
      <Button
        isDisabled={username === '' || isPending}
        isLoading={isPending}
        color="primary"
        size="sm"
        variant="light"
        className="self-end"
        onPress={handleForgotPassword}
      >
        ¿Olvidaste tu contraseña?
      </Button>
      <Button
        fullWidth
        isDisabled={!isFormFilled}
        isLoading={loading}
        color="primary"
        onClick={handleLogin}
      >
        Iniciar Sesión
      </Button>
      <p className="text-center text-small">
        ¿Aún no tienes una cuenta?{' '}
        <Link as="button" size="sm" onPress={() => setSelected('sign-up')}>
          Registrarse
        </Link>
      </p>
    </form>
  )
}
