import { object, string } from 'yup'

export const BranchOfficeSchema = object().shape({
  department: string().required('Departamento requerido').min(4, 'El departamento de la empresa debe tener al menos 4 caracteres'),
  location: string().required('Localidad requerida').min(4, 'La localidad de la empresa debe tener al menos 4 caracteres'),
  address: string().required('Dirección requerida').min(4, 'La dirección de la empresa debe tener al menos 10 caracteres'),
  phone_number: string().required('Número de teléfono requerido').min(4, 'El número de teléfono de la empresa debe tener al menos 4 caracteres'),
  responsible_name: string().required('Nombre del responsable requerido').min(4, 'El nombre del responsable de la empresa debe tener al menos 4 caracteres'),
  email: string().required('Correo electrónico requerido').min(4, 'El correo electrónico de la empresa debe tener al menos 4 caracteres')
})
