import { theme } from 'antd'

const { defaultAlgorithm } = theme

export const antTheme = {
  token: {
    colorPrimary: '#1CACDD',
    colorError: '#df3562',
    colorWarning: '#e9a845',
    colorSuccess: '#5ec66f'
  },
  algorithm: defaultAlgorithm
}
