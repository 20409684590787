import { GoDotFill } from 'react-icons/go'
import { Button } from '@nextui-org/react'

export default function Navigation({
  step,
  onPreviousStep,
  onNextStep,
  children,
  isNextStepDisabled
}) {
  const previousAvailableSteps = [2]
  const nextAvailableSteps = [1, 2]

  const isPreviousDisabled = !previousAvailableSteps.includes(step)
  const isNextDisabled = !nextAvailableSteps.includes(step) || isNextStepDisabled

  return (
    <div className="flex gap-2 justify-between items-center pt-2">
      <Button
        variant="bordered"
        color="primary"
        onPress={onPreviousStep}
        className="px-10"
        isDisabled={isPreviousDisabled}
      >
        Atrás
      </Button>
      <div className=" flex gap-2">
        {[1, 2].map((s) => (
          <GoDotFill
            key={s}
            size={20}
            className={`text-xl ${s === step ? 'text-primary' : 'text-gray-400'}`}
          />
        ))}
      </div>
      {children || (
        <Button
          variant="bordered"
          color="primary"
          onPress={onNextStep}
          className="px-10"
          isDisabled={isNextDisabled}
        >
          Continuar
        </Button>
      )}
    </div>
  )
}
