import { Button, Checkbox, Input, Select, SelectItem } from '@nextui-org/react'
import { Form, FormikProvider, useFormik } from 'formik'
import CustomModal from 'src/components/CustomModal'
import { finalDestination } from 'src/constants'
import WasteCard from 'src/pages/Providers/components/WasteCard'
import { TraceabilityItemSchema } from 'src/schemas/Traceability'

export default function AddTraceabilityItem({
  formik: formikBase,
  selectedPickup,
  items,
  isOpen,
  onOpenChange
}) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      waste_id: '',
      weight: '',
      unit: 'kg',
      final_destination: new Set([])
    },
    validationSchema: TraceabilityItemSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
        final_destination: values.final_destination.values().next().value
      }
      formikBase.values.items.push(data)
      resetForm()
      onOpenChange()
    }
  })

  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue } = formik

  return (
    <CustomModal size="lg" isOpen={isOpen} onOpenChange={onOpenChange}>
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
          {selectedPickup?.wastes && (
            <div className="flex flex-col gap-2">
              <span className="pl-1 text-md text-gray-600">Residuo</span>
              {selectedPickup?.wastes?.map((waste) => (
                <div className="flex gap-2" key={waste.id}>
                  <Checkbox
                    isDisabled={items.some((i) => i.waste_id === waste.id)}
                    isSelected={values?.waste_id === waste.id}
                    onValueChange={() => setFieldValue('waste_id', waste.id)}
                  />
                  <WasteCard waste={waste} />
                </div>
              ))}
            </div>
          )}

          <Input
            {...getFieldProps('weight')}
            label="Peso"
            placeholder="1.23"
            isInvalid={Boolean(touched.weight && errors.weight)}
            errorMessage={touched.weight && errors.weight}
            endContent={
              <div className="flex items-center">
                <select
                  className="text-lg outline-none bg-transparent border-l-1 border-l-gray-300 pl-3"
                  id="currency"
                  name="currency"
                  onChange={(e) => setFieldValue('unit', e.target.value)}
                >
                  <option>kg</option>
                  <option>g</option>
                  <option>lt</option>
                  <option>m³</option>
                </select>
              </div>
            }
            type="number"
          />
          <Select
            size="sm"
            label="Destino Final"
            selectedKeys={values.final_destination}
            onSelectionChange={(value) => {
              setFieldValue('final_destination', value)
            }}
          >
            {finalDestination?.map((d) => (
              <SelectItem key={d.value} value={d.value}>
                {d.label}
              </SelectItem>
            ))}
          </Select>

          <Button type="submit" color="primary" isDisabled={!formik.isValid}>
            Guardar
          </Button>
        </Form>
      </FormikProvider>
    </CustomModal>
  )
}
