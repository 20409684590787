import { Button } from '@nextui-org/react'
import CustomModal from 'src/components/CustomModal'
import { PiWarningCircleBold } from 'react-icons/pi'
import useUnassignProvider from '../../hooks/useUnassignProvider'

export default function ProviderDeleteModal({ isOpen, onOpenChange, provider }) {
  if (!provider) return null

  const { mutate } = useUnassignProvider(onOpenChange)
  const handleDelete = (id) => {
    mutate({ id })
  }

  return (
    <CustomModal size="lg" isOpen={isOpen} onOpenChange={onOpenChange}>
      <div className="flex flex-col gap-4 p-2">
        <div className="text-center">
          <h2 className="text-xl">Desvincular Gestor</h2>
          <p className="text-sm max-w-sm text-slate-500">
            ¿Está seguro que desea desvincular el gestor{' '}
            <span className="font-bold">{provider?.name}</span>?
          </p>
        </div>

        <div className="flex gap-2 p-4 bg-amber-100 rounded-md">
          <PiWarningCircleBold className="text-amber-500 text-xl w-10" />
          <div className="flex flex-col gap-1 ">
            <span className="text-md text-amber-500">Advertencia</span>
            <p className="text-xs max-w-md font-extralight text-amber-500">
              Al desvincular un gestor, todos los residuos gestionados y certificaciones asociadas
              hasta el momento quedarán en el gestor pero no podrás utilizarlo más en tus
              recolecciones.
            </p>
          </div>
        </div>
        <Button color="danger" onPress={() => handleDelete(provider.id)}>
          Desvincular
        </Button>
      </div>
    </CustomModal>
  )
}
