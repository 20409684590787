import { Form, FormikProvider, useFormik } from 'formik'
import { Button, Select, SelectItem } from '@nextui-org/react'
import wasteCategories from 'src/data/wasteCategories.json'
import { useDispatch } from 'react-redux'
import { addWaste } from 'src/store/features/wasteSlice'
import useCreateWaste from '../../hooks/useCreateWaste'

const types = [
  {
    label: 'Tipo 1',
    value: 1
  },
  {
    label: 'Tipo 2',
    value: 2
  }
]

export default function ManagedWastesForm({ onOpenChange, provider }) {
  const dispatch = useDispatch()
  const { mutate } = useCreateWaste(onOpenChange)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: null,
      category_code: '',
      subcategory_code: ''
    },
    onSubmit: async (values) => {
      const data = {
        id: self.crypto.randomUUID(),
        type: values.type,
        category_code: values.category_code,
        category_name: wasteCategories.find((waste) => waste.code === values.category_code).name,
        subcategory_code: values.subcategory_code,
        subcategory_name: wasteCategories
          .find((waste) => waste.code === values.category_code)
          .subcategories.find((waste) => waste.code === values.subcategory_code).name
      }

      if (provider) {
        mutate({ providerId: provider.id, data })
      } else {
        dispatch(addWaste(data))
        onOpenChange()
      }
    }
  })
  const { values, handleSubmit, getFieldProps } = formik

  const subcategories = wasteCategories
    .find((category) => category.code === values.category_code)
    ?.subcategories.filter((s) => s?.type?.toString() === values.type)

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col gap-4 " onSubmit={handleSubmit}>
        <Select
          label="Categoria"
          {...getFieldProps('category_code')}
          renderValue={(items) => {
            return items.map((item) => {
              return item.props.children
            })
          }}
        >
          {wasteCategories?.map((waste) => (
            <SelectItem key={waste.code} value={waste.code}>
              {waste.code} - {waste.name}
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Tipo de residuos"
          isDisabled={!values.category_code}
          {...getFieldProps('type')}
        >
          {types?.map((type) => (
            <SelectItem key={type.value} value={type.value}>
              {type.label}
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Subcategoría"
          isDisabled={!values.type || !values.category_code || subcategories?.length === 0}
          {...getFieldProps('subcategory_code')}
          renderValue={(items) => {
            return items.map((item) => {
              return item.props.children
            })
          }}
        >
          {subcategories?.map((type) => (
            <SelectItem key={type.code} value={type.code}>
              {type.code} - {type.name}
            </SelectItem>
          ))}
        </Select>
        <Button type="submit" color="primary">
          Guardar Residuo
        </Button>
      </Form>
    </FormikProvider>
  )
}
