import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'

class S3Manager {
  s3

  constructor () {
    this.s3 = new S3Client({
      region: import.meta.env.VITE_AWS_REGION,
      credentials: {
        accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
        secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY
      }
    })
  }

  downloadFile = async (Key) => {
    const command = new GetObjectCommand({ Bucket: import.meta.env.VITE_AWS_BUCKET_NAME, Key })
    return await getSignedUrl(this.s3, command, { expiresIn: 3600 })
  }

  uploadFile = (file, Key) => {
    const command = new PutObjectCommand({
      Bucket: import.meta.env.VITE_AWS_BUCKET_NAME,
      Key,
      Body: file
    })

    return new Promise((resolve, reject) => {
      this.s3.send(command, (err, data) => {
        if (err) { reject(err) }
        resolve(data)
      })
    })
  }
}

export default S3Manager
