import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import APIClient from 'src/services/apiClient'
import { assignOrganization, finishOnboarding } from './authSlice'

const apiClient = new APIClient('/organizations')
const apiClient2 = new APIClient('/organizations/branch_offices')

export const fetchOrganizations = createAsyncThunk('organizations/fetchOrganizations', async () => {
  return await apiClient.getAll()
})

export const fetchUserOrganization = createAsyncThunk(
  'organizations/fetchUserOrganization',
  async (id, thunkAPI) => {
    try {
      return await apiClient.get(id)
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const createOrganization = createAsyncThunk(
  'organizations/createOrganization',
  async (data, thunkAPI) => {
    try {
      const response = await apiClient.post(data)
      thunkAPI.dispatch(assignOrganization(response.id))
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const editOrganization = createAsyncThunk(
  'organizations/editOrganization',
  async ({ id, data }, thunkAPI) => {
    try {
      return await apiClient.put(id, data)
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const deleteOrganization = createAsyncThunk(
  'organizations/deleteOrganization',
  async (id, thunkAPI) => {
    try {
      return await apiClient.delete(id)
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const fetchOrganizationBranchOffices = createAsyncThunk(
  'organizations/fetchOrganizationBranchOffices',
  async (id, thunkAPI) => {
    try {
      return await apiClient.get(id + '/branch_offices')
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const createBranchOffice = createAsyncThunk(
  'organizations/createBranchOffice',
  async (data, thunkAPI) => {
    try {
      return await apiClient2.post(data)
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const deleteBranchOffice = createAsyncThunk(
  'organizations/deleteBranchOffice',
  async (branchOfficeId, thunkAPI) => {
    try {
      return await apiClient2.delete(branchOfficeId)
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

export const completeOnboarding = createAsyncThunk(
  'organizations/completeOnboarding',
  async (id, thunkAPI) => {
    try {
      const response = await apiClient.put(id + '/onboarding', {})
      thunkAPI.dispatch(finishOnboarding())
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.response.data.detail })
    }
  }
)

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: [],
    branchOffices: [],
    userOrganization: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers (builder) {
    builder
      .addCase(fetchOrganizations.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(fetchUserOrganization.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchUserOrganization.fulfilled, (state, action) => {
        state.userOrganization = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchUserOrganization.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(createOrganization.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(editOrganization.pending, (state, action) => {
        state.loading = true
      })
      .addCase(editOrganization.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(editOrganization.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(deleteOrganization.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(fetchOrganizationBranchOffices.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchOrganizationBranchOffices.fulfilled, (state, action) => {
        state.branchOffices = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchOrganizationBranchOffices.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(createBranchOffice.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createBranchOffice.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(createBranchOffice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(deleteBranchOffice.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteBranchOffice.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteBranchOffice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })

      .addCase(completeOnboarding.pending, (state, action) => {
        state.loading = true
      })
      .addCase(completeOnboarding.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(completeOnboarding.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})

export default organizationsSlice.reducer
