import { useQuery } from '@tanstack/react-query'
import APIClient from 'src/services/apiClient'

const client = new APIClient('/organizations')

export default function useGetOrganizations () {
  return useQuery({
    queryKey: ['organizations'],
    queryFn: client.getAll
  })
}
