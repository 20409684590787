import { useMemo } from 'react'
import organizationCategories from 'src/data/organizationCategories.json'

export default function useGetCategories (category) {
  const subcategories = useMemo(
    () => organizationCategories.find((c) => c.name === category)?.subcategories,
    [category]
  )

  return { categories: organizationCategories, subcategories }
}
