import { Button, Card } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from 'src/store/features/authSlice'
import OnboardingBranchOffices from './components/OnboardingBranchOffices'
import OnboardingConfirmation from './components/OnboardingConfirmation'
import OnboardingOrganization from './components/OnboardingOrganization'
import OnboardingWelcome from './components/OnboardingWelcome'
import useNavigation from './hooks/useNavigation'

export default function OnboardingPage() {
  const dispatch = useDispatch()
  const user = useSelector((s) => s.auth.user)

  const { step, handleNextStep } = useNavigation()
  const handleLogout = () => {
    dispatch(logoutUser())
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.7,
          delay: 0.3,
          ease: 'easeInOut'
        }}
      >
        <Card className="relative flex flex-col gap-4 h-[720px] w-[530px] p-6 sm:p-12 overflow-y-scroll no-scrollbar rounded-3xl">
          {step === 1 && <OnboardingWelcome user={user} onNextStep={handleNextStep} />}
          {step === 2 && <OnboardingOrganization onNextStep={handleNextStep} />}
          {step === 3 && <OnboardingBranchOffices onNextStep={handleNextStep} />}
          {step === 4 && <OnboardingConfirmation onNextStep={handleNextStep} />}
        </Card>
      </motion.div>
      <Button
        color="primary"
        className="absolute top-8 right-8"
        variant="light"
        onClick={handleLogout}
      >
        Cerrar Sesión
      </Button>
    </>
  )
}
